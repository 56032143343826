import React, { useEffect, useState } from "react"
import { useNavigate  } from 'react-router-dom';
import { dashboardapi, subscriberswaitinglist } from "../Apicall";


export default function Homecontent(){

    const navigate = useNavigate()
    useEffect(() =>{
        if(!localStorage.getItem('token')){
            navigate('/login')
        }
    },[])

    const [data, setdata] = useState("")

    const dashboarddata = () =>{
         dashboardapi()
        .then((res)=>{
            // console.log("dasboardres",res);
            setdata(res.data.data)
        })
        .catch((err)=>{
            // console.log("dashboarderr", err);
            
            if(err.response.status === 401){
                localStorage.removeItem('token')
                window.location.reload(true)
            }
        })
    } 

    const [subscriberscount, setsubscriberscount] = useState(0)
    
    const waitingsubscriberslist = () =>{
        const pageno = 0
        subscriberswaitinglist(pageno)
        .then((res)=>{
            setsubscriberscount(res.data.data.count)
        })
        .catch((err)=>{
            // console.log("subcriberserr", err)
        })
      }

      useEffect(()=>{
        waitingsubscriberslist()
        dashboarddata()
      },[])

    return(
        <>
            <div style={{marginTop:'10px'}} className="flex align-items-center" >
                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round ml-3" style={{ width: '2.5rem', height: '2.5rem' }}><i className="pi pi-home text-blue-500 text-xl" style={{'fontSize': '16px' }}></i> </div> <h3 className='ml-1'>Dashboard</h3>
                    </div>
                    {/* <small className="ml-6 mt-6 flex align-items-center justify-content-center">In Progress</small> */}
                    
                    <div className="grid home-content">
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">No. of Subscribers</span>
                                        <div className="text-900 font-medium text-xl">{data.total}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-users text-blue-500 text-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Subscription Requests</span>
                                        <div className="text-900 font-medium text-xl">{subscriberscount}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-comments text-orange-500 text-xl"></i>
                                    </div>
                                </div>
                                {/* <span className="text-green-500 font-medium">%52+ </span>
                                <span className="text-500">since last week</span> */}
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Subscription Expired</span>
                                        <div className="text-900 font-medium text-xl">{data.expired}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-exclamation-circle text-cyan-500 text-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Near Expiration</span>
                                        <div className="text-900 font-medium text-xl">{data.overdue}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-exclamation-triangle text-cyan-500 text-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">No. of Users Registered</span>
                                        <div className="text-900 font-medium text-xl">{data.users}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-user-plus text-purple-500 text-xl"></i>
                                    </div>
                                </div>
                                {/* <span className="text-green-500 font-medium">85 </span>
                                <span className="text-500">responded</span> */}
                            </div>
                        </div>
                       
                    </div> 
        </>

    )
}



