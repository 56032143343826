import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { useNavigate  } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import  { catdatafetch, albumdata, savealbum, delalbum, updatealbum,imagesignedurl, getuser, addrestrictedusers, restricteduserlist,deluser, getusers, deleteuser }  from '../Apicall';import './Albums.css';

export default function Albums() {

    const navigate = useNavigate()
    useEffect(() =>{
        if(!localStorage.getItem('token')){
            navigate('/login')
        }
    },[])

  let emptyProduct = {
      id: null,
      name: '',
      Profile_pic: null,
      description: '',
      restricted: '',
      category_id: ''
  };

//   const [products, setProducts] = useState(null);
  const [productDialog,   setProductDialog] = useState(false);
  const [updateDialog,   setupdateDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
//   const [updated, setUpdated] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const userdt = useRef(null);
  

  const openNew = () => {
    //   setProduct(emptyProduct);
      setSubmitted(false);
      setProductDialog(true);
  }

  const hideDialog = () => {
      

      setalbumName('');
      setalbumDescription('');
      setprofilePicture('');
      setmessage('');
      setSuccess(false)
      setRestrictedChecked(''); 
      setSubmitted(false);
      setProductDialog(false);

  }

  const hideupdateDialog = () => {
    setprofilePicture('')
    setmessage('');
    setSuccess(false)
    setupdateDialog(false);
    setFilterValue("");
}



  const hideDeleteProductDialog = () => {
      setDeleteProductDialog(false);
  }
  const [totalusers, setTotalUsers] = useState([]);
  const [users, setUsers] = useState([])
  console.log("users", users);
  const [usersid, setUsersID] = useState('')
  console.log("Albums ~ usersid:", usersid)

  const saveProduct = () => {
      setSubmitted(true);
    
      const body = {  
        name: albumName,
        description: albumDescription,
        profile_pic: profilePicture,
        restricted: res,
        catid: albumId,
        restrictedusers: usersid,
        orderno: orderno

    }
    // console.log("savedata", body);
    if(!albumName || !albumDescription || !profilePicture || !albumId || !orderno ){
        setProductDialog(true)
    }
   else{
    savealbum(body)
    .then(function (response) { 
        // console.log(response);
        
        toast.current.show({ severity: 'info', summary: 'Album Created', detail: 'Created Sucessfully',life: 3000});
        setProductDialog(false)
        datafetch()
        setalbumName('');
        setalbumDescription('');
        setprofilePicture('');
        setmessage('');
        setSuccess(false)
        setres('');
    })


    .catch(function (error) {
     
        setProductDialog(false)
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
        setalbumName('');
        setalbumDescription('');
        setprofilePicture('');
        setmessage('');
        setSuccess(false)
        // setCategory('');
        setres('');
    });      
        
   }
}

const [updateorderno, setupdateOrderno] = useState("");
const [updateName, setupdatealbumName] = useState('');
const [updateDescription, setupdatealbumDescription] = useState('');
const [updateprofilePicture, seupdatetprofilePicture] = useState('');
const [updatecategoryID, setupdatecatID] =useState('');
const [updateres, setupdateres] = useState();
const[updateid,setupdateId] = useState();
const [updaterestrictedusers,setupdaterestrictedusers] = useState()
console.log("updaterestrictedusers:", updaterestrictedusers)



const editProduct = (id) => {
  
    setupdateOrderno(id.orderno)
    setupdateDialog(true);
    setupdatealbumName(id.name)
    setupdatealbumDescription(id.description)
    seupdatetprofilePicture(id.profile_pic) 
    setupdatecatID(id.category_id)
    id.restricted === 1 ? setupdateres(true) : setupdateres(false)
    setupdateId(id)
    setupdaterestrictedusers(id.restrictedusers)
    
}

const [updated, setUpdated] = useState(false)

const updateProduct = () =>{
    
    setUpdated(true)
    var body = {
        name: updateName,
        description:  updateDescription,
        profile_pic: updateprofilePicture,
        restricted: updateres ? 1 : 0,
        catid: updatecategoryID,
        restrictedusers: updateres ? updaterestrictedusers: null,
        orderno: updateorderno,
        id: updateid.id
    
    }

    if(!updateName || !updateDescription || !updateprofilePicture || !updatecategoryID || !updateorderno ){
        setupdateDialog(true)
    }
   else{
    
    updatealbum(body)
    .then(function (response) {
        // console.log("alb update res",response)
        datafetch()
        toast.current.show({ severity: 'info', summary: 'Album Updated', detail: 'Update Sucessfully',life: 3000 });
        setFilterValue("");
        setupdateDialog(false);
        setmessage('')
        setSuccess(false)
       
    })
    .catch(function (error) {
        // console.log("alb update err",error);
        setupdateDialog(false);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

        // console.log(error)
    });      
   }
    // setUpdated(true)s
    
} 

  const [albumdeleteid, setDeleteId] = useState()
  const confirmDeleteProduct = (product) => {
      setProduct(product);
      setDeleteId(product)
      setDeleteProductDialog(true);
  }

  const deleteProduct = () => {

    var body = {  
        id: albumdeleteid.id
    }

    delalbum(body)
    .then(function (response) {

        // console.log(response);
        // setDeleteMsg(response)
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
        datafetch()
    })
    .catch(function (error) {
        // console.log(error);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

    });      
    

setDeleteProductDialog(false);
setProduct(emptyProduct);

  }


const [adduseralbumid ,setuseralbumID] = useState('')


const addrestrictedUser = () =>{
    setLoading(true)
    usersid?
        usersid.map((userid)=>{
           
            const body ={
                user_id : userid,
                album_id : adduseralbumid.id 
            }
    
           
    
            addrestrictedusers(body)
             .then(function (response) {
                // console.log(response);
                // restricteduserlists()
                
                setUsersID(null)
                
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Added successfully', life: 3000 });   
                
            })
            .catch(function (error) {
                // console.log(error);
                toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
                // restricteduserlists()
                
            });      
        })
    : setadduserDialog(true)
        
    
   }




  const   leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
              {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
          </React.Fragment>
      )
  }

const [adduserDialog, setadduserDialog] = useState(false)

//   const adduser = (id) =>{
//     setuseralbumID(id)
//     setadduserDialog(true)
    
//   }


  const hideadduserDialog = () =>{
    setUsersID(null)
    setadduserDialog(false)
    setuseralbumID("")
   
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              {/* <Button icon="pi pi-plus" className="p-button-rounded p-button-info mr-2 p-1" onClick={() => adduser(rowData)} /> */}
              <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
              <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={() => confirmDeleteProduct(rowData)} />
          </React.Fragment>
      );
  }

  const redtictedBodyTemplate = (rowData) =>{
    if(rowData.restricted === 0){
        return <Tag severity="info" value="No"></Tag>
    }
    if(rowData.restricted === 1){
        return <Tag severity="success" value="Yes"></Tag>
    }
    
  }

  const header = (
      <div className="table-header">
          <h5 className="mx-0 my-1">Manage Albums</h5>
          {/* <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span> */}
      </div>
  );
  const productDialogFooter = (
      <React.Fragment>
        <div className='mt-0'>
          <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </div>
      </React.Fragment>
  );

  const updateDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideupdateDialog} />
        <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
      </div>
    </React.Fragment>
);

const adduserdialogFooter =(
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideadduserDialog} />
        <Button label="Add User" icon="pi pi-check" className="p-button-text" onClick={addrestrictedUser} />
      </div>
    </React.Fragment>
)

  const deleteProductDialogFooter = (
      <React.Fragment>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
      </React.Fragment>
  );
 




  const [count, setCount] = useState([]);
  const [albums, setAlbums] = useState([]); 
  const [pages, settotalPages] = useState(0);
  const [fileurl, setFileUrl] = useState('')

   const datafetch = () => {
    albumdata (pages) 
    .then((res) => {
        // console.log("album data", res );
        setFileUrl(res.data.data.url)
        setAlbums(res.data.data.rows.map((infoalbum)=>(infoalbum.album_info) ) );
        setCount(res.data.data.count);
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        });
        
  }
  useEffect(() => {
    datafetch()
  }, [])

  useEffect(()=>{
    datafetch()
  },[pages])


  const imageBodyTemplate = (rowData) => {
 
    return <img src={fileurl+rowData.profile_pic} className="product-image" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.profile_pic} />
  
}

  

//   Add albums

const [orderno, setOrderno]  = useState('');
const [albumName, setalbumName] = useState('');
const [albumDescription, setalbumDescription] = useState('');
const [profilePicture, setprofilePicture] = useState('');
const [res, setres] = useState(0)
// const [plan, setPlan] =useState('')


const [reschecked, setRestrictedChecked] = useState();


    useEffect(()=>{
        reschecked?setres(1):setres(0)
    },[reschecked])
    

   
    const [cat, setcat] = useState([]); 

    const catdata = () =>{
            catdatafetch () 
        .then((res) => {
        // console.log("api response", res.data.data.rows);
        setcat(res.data.data.rows);
        })
        .catch((err) => { 
            
            if(err.response.status === 401){
                localStorage.removeItem('token')
                navigate("/");
            }
        });
    }


    useEffect(() => {
      catdata()
    }, [])

const [albumId, albumvalue] = useState(albums.value)

const albumidHandler = (e) =>{
    albumvalue(e.value)   
}


const [message, setmessage] = useState(' ')
const [success, setSuccess] = useState(false)



    const myUploader = async (file) =>{
    
        const today = new Date(),
        date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString() + today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
        
        const name = file.files[0].name
        // console.log("fileeee", name);
        const filename = name.split(".")[0]+"_"+date+"."+name.split(".")[1]
        // console.log("filename", filename);
        const body = {
            locae: 'album',
            fileName: filename,
            fileType: file.files[0].type            
        }
        
        imagesignedurl(body)
        .then((response)=> {
            // console.log(response)
            
            // console.log('albumminsidfeurl',response.data.url)
            setprofilePicture(filename)
            setmessage("File Uploading...")
            seupdatetprofilePicture(filename)
            // console.log('fileURLLLLL', response.data.fileurl)

            const uploadimg = () =>{

                return new Promise((resolve, reject) => {
              
                    try{
            
                        const result = fetch(response.data.url, {
                
                            method: "PUT",
                            headers:{
                                "Content-Type": file.files[0].type,
                                
                            }, 
                            body: file.files[0],
                            
                            });
                        resolve(result)

                
                        }
                        catch(e){
                            
                            reject(e)
                        }
              });
            }

            uploadimg().then((res)=>{
                // console.log("imageres", res);
                setmessage("File Upload Successfully")
                setSuccess(true)
                })
                .catch((err)=>{
                    console.log("imageer", err);
                    setmessage("Upload Failed")
                })
            
        })
        .catch( (error) =>{
            // console.log(error)
        });      
        
        
    }

    
   
   
    const [first1, setFirst1] = useState(0);


    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        
    }
   
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Previous</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            // console.log(options.onClick)
            
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Next</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            // console.log(options)
            
            settotalPages(options.currentPage)
           
            // console.log('pages',pages)
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
           

            return (
                <button type="button" className={options.className} onClick={options.onClick}  >
                    
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
      
    };
   
    
    // const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        // const value = totalSize/10000;
        
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center',height:'63px'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                
            </div>
        );
    }


    const updateitemTemplate = (file, props) => {
        return (
            <>
        
            <div className="flex align-items-center flex-wrap p-1" >
                <div className="flex align-items-center" style={{width: '100%', marginLeft: '-12px', marginTop: '-10px'}}>
                    <img alt={file.name} role="presentation" src={fileurl+updateprofilePicture} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name} 
                        
                    </span>
                    <span className="flex flex-column text-left ml-3">
                        {/* {props.formatSize} */}
                    </span>
                     

                    <div>
                        
                        {  success?
                                <div > 
                                <Tag icon="pi pi-check m-1" severity="warning" className="p-button-warning p-button-rounded p-button-fill mt-1 ml-4 px-1 py-1"  style={{ borderRadius: '50%'}} />
                                </div>
                                        
                                
                                    :<p>{}</p>
                                    
                                }
                    </div>

                </div>
        
                {
                    message?
                    <span className='small mt-1' style={{marginBotton: '-10px', marginLeft: '-10px',color:'grey'}}>{message}</span>
                    :<p>{}</p>
                }
                
                

            </div>
            
            </>
        )
    }


    const itemTemplate = (file, props) => {
        return (
            <>
        
            <div className="flex align-items-center flex-wrap p-1" >
                <div className="flex align-items-center" style={{width: '100%', marginLeft: '-12px', marginTop: '-10px'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name} 
                        
                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                    </span>
                    <span className="flex flex-column text-left ml-3">
                        {props.formatSize} 
                    </span>
                     

                    <div>
                        {  success?
                                <div > 
                                <Tag icon="pi pi-check m-1" severity="warning" className="p-button-warning p-button-rounded p-button-fill mt-1 ml-4 px-1 py-1"  style={{ borderRadius: '50%'}} />
                                </div>
                                        
                                
                                    :<p>{}</p>
                                    
                                }
                    </div>

                </div>
        
                {
                    message?
                    <span className='small mt-1' style={{marginBotton: '-10px', marginLeft: '-10px',color:'grey'}}>{message}</span>
                    :<p>{}</p>
                }
                
                

            </div>
            
            </>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{height: '70px'}}>
                <i className="pi pi-image" style={{ borderRadius: '50%',marginTop: '-20px', backgroundColor: 'var(--surface-b)',fontSize:'65px', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="m-1">Drag and Drop Image Here</span>
            </div>
        )
    }

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined p-2'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined p-2'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined p-2'};

   const adduserhandler = (e) =>{
    setUsersID(e.value)
    setupdaterestrictedusers(e.value)
   }


    

    const getuser = useCallback(async() => {
        
        getusers () 
        .then((res) => {
            // console.log('res ' , res)
            setUsers(res.data.data)
            setTotalUsers(res.data.data)
        })
        .catch((err) => {
            if(err.response.status === 401){
                localStorage.removeItem('token')
                navigate("/");
            }
            });
      
      })

      useEffect(()=>{
        getuser()
      },[])

    //   const [userslist, setuserslist] = useState("")
      const [loading, setLoading] = useState(false)

    //   const restricteduserlists  = () => {
    //     console.log("restricted users fuct called");
    //      restricteduserlist (adduseralbumid.id) 
    //     .then((res) => {
            
    //         setuserslist(res.data.data.map((user)=>(
    //             user
    //         )))
    //         setLoading(false)
            
           
    //     })
    //     .catch((err) => {
    //         setuserslist("")
    //     setLoading(false)     
    //     }); 
    //   }
    
    //  useEffect(()=>{
       
    //     restricteduserlists()
    //   },[adduseralbumid])

      
   

const hideDeleteUserDialog = () => {
    
    setDeleteUserDialog(false);
    
}

const deleteUser = () =>{

    // console.log(userdeleteid)
    const body = {
        id: userdeleteid
    }

    deluser(body)
    .then((res)=>{
        // console.log(res)
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
        // console.log("delete");
        setDeleteUserDialog(false);
       
        
    })
    .catch((err)=>{
        // console.log(err)
        toast.current.show({ severity: 'error', summary: 'Warning', detail: 'Something went wrong', life: 3000 });
       
    })

    
 
}
    


const deleteUserDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
    </React.Fragment>
);


const [deleteUserDialog, setDeleteUserDialog] = useState(false);
const [userdeleteid, setuserdeleteId] = useState()  
const [user, setUsername] = useState(' ')

    const confirmDeleteUser = (data)=>{
        // console.log(data.id)
        setuserdeleteId(data.id)
        setUsername(data.name)
        setDeleteUserDialog(true);
    }

    const deleteTemplate = (rowData) => {
       
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" style={{fontSize: '-10px'}} className="p-button-rounded p-button-warning" onClick={() => confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }



   
  const [filterValue, setFilterValue] = useState('');


  const onFilterChange = (e) => {
    const value = e.target.value;
    setFilterValue(value);
    if (value) {
        setUsers(
            totalusers.filter(user =>
            user.phoneno && user.phoneno.toLowerCase().includes(value.toLowerCase())
          )
      );
    } else {
      setUsers(totalusers);
    }
  };

//   const clearFilter = () => {
//     setFilterValue('');
//     setUsersID(users);
//   };

const clearFilter = () => {
    setFilterValue('');
    setUsers(totalusers);
}

  const customHeader = (
    <div className="p-multiselect-header">
      <InputText
        type="text"
        value={filterValue}
        onChange={onFilterChange}
        placeholder="Search by mobile number"
        style={{ width: '100%' }}
      />
      <Button
        icon="pi pi-times"
        onClick={clearFilter}
        className="p-button-text"
        style={{ marginLeft: '10px' }}
      /> 
    </div>
  );
  
    const [restrictedUsers, setrestrictedUsers] = useState([])
    console.log("restrictedUsers:", restrictedUsers)
    useEffect(() => {
        if (updaterestrictedusers) {
            const filteredUsers = users.filter(user => updaterestrictedusers.includes(user.id));
            setrestrictedUsers(filteredUsers);
        }else{
            setrestrictedUsers([]);
        }
    }, [updaterestrictedusers, users]);
        
const [restrictedUserDialog, setRestrictedUserDialog] = useState(false);
const hideRestrictedUserDialog = () => {
    
    setRestrictedUserDialog(false);
    
}

// const deleteUser = () =>{

//     // console.log(userdeleteid)
//     const body = {
//         id: userdeleteid
//     }

//     deluser(body)
//     .then((res)=>{
//         // console.log(res)
//         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
//         // console.log("delete");
//         setDeleteUserDialog(false);
       
        
//     })
//     .catch((err)=>{
//         // console.log(err)
//         toast.current.show({ severity: 'error', summary: 'Warning', detail: 'Something went wrong', life: 3000 });
       
//     })

    
 
// }

// const restrictedUserDialogFooter = (
//     <React.Fragment>
//         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRestrictedUserDialog} />
//         <Button label="Yes" icon="pi pi-check" className="p-button-text" />
//     </React.Fragment>
// );

const restrictedUsersHandler = (e) => {
    setRestrictedUserDialog(true);
}


const [deleteRestrictedUserDialog, setDeleteRestrictedUserDialog] = useState(false);

const hideDeleteRestrictedUserDialog = () => {
    setDeleteRestrictedUserDialog(false);
}
const [deleteRestrictedUserId, setDeleteRestrictedUserId] = useState()

const userdelete = ()=>{
   
    const body = { 
        user_id: deleteRestrictedUserId.id
    }
    console.log("userdelete ~ body:", body)


    deleteuser(body)
    .then((res)=>{
  
        getusers();
        datafetch();
        setDeleteRestrictedUserDialog(false);
        toast.current.show({ severity: 'info', summary: 'Delete Subscriber', detail: 'Subscriber Deleted Successfully',life: 3000 });
        window.location.reload();
    }).catch((err)=>{
      
        if(err.response.data.message === "இந்த உறுப்பினருக்கு சந்தா தகவல் உள்ளது. ஆகவே உறுப்பினர் தகவலை நீக்க முடியாது. " ){
            setDeleteRestrictedUserDialog(false);
            
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'இந்த உறுப்பினருக்கு சந்தா தகவல் உள்ளது. ஆகவே உறுப்பினர் தகவலை நீக்க முடியாது.', life: 10000 });
        }else{
            setDeleteRestrictedUserDialog(false);
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
        }
        

    })

}

const deleteRestrictedUserDialogFooter = (
    <React.Fragment>
         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRestrictedUserDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>userdelete()} /> 
    </React.Fragment>
);

const deleteRestrictedUser = (data) =>{
    setDeleteRestrictedUserId(data)
    setDeleteRestrictedUserDialog(true)
    // console.log("DELTE", data)
    // setname((data.name))
}

  return (
    <>
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
              <Toolbar className="mb-4" style={{position:"sticky"}} left={leftToolbarTemplate} ></Toolbar>
    
              <DataTable ref={dt} value={albums} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id" paginator onPage={onCustomPage1}
                  paginatorTemplate={template1} first={first1} rows={10} totalRecords={count} lazy 
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Albums"  
                  globalFilter={globalFilter} header={header} responsiveLayout="scroll"  >
                
                  <Column field="category_name" header="Category" className='text-center' style={{ minWidth: '12rem' }}></Column>
                  <Column field="name"  header="Name" style={{ minWidth: '12rem' }}></Column>
                  <Column field="description" header="Description" style={{ minWidth: '16rem' }}></Column>
                  <Column field="profile_pic" header="Profile Pic" body={imageBodyTemplate}></Column>
                  <Column field="restricted" body={redtictedBodyTemplate} header="Restricted" className='text-center' style={{ minWidth: '8rem' }}></Column>
                  <Column field="orderno" header="Order" className='text-center' style={{ minWidth: '8rem' }}></Column>
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              
              </DataTable>
          </div>

          <Dialog visible={productDialog} style={{ width: '450px' }} header="Add Album" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
            <div className="field">
                  <label htmlFor="name">Order No.</label>
                  <InputText id="name" keyfilter="int" value={orderno} onChange={(e) => setOrderno(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !orderno })} 
                   />
                  {submitted && !orderno && <small className="p-error">Order Number required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="name">Name</label>
                  <InputText id="name" value={albumName} onChange={(e) => setalbumName(e.target.value)} required  className={classNames({ 'p-invalid': submitted && !albumName })} 
                   />
                  {submitted && !albumName && <small className="p-error">Name is required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="description">Description</label>
                  <InputTextarea id="description" value={albumDescription} onChange={(e) => setalbumDescription(e.target.value)} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !albumDescription })}  />
                  {submitted && !albumDescription && <small className="p-error">Description is required.</small>}

              </div>

              <div className="field">
                  <label className="mb-3">Profile Picture</label> 
                  <div className="formgrid grid flex align-items-center">     
                    <div className='col-12'>
                        {/* <FileUpload name="demo"  mode="basic" className='col-3 max' customUpload  uploadHandler={myUploader}  accept="image/*" />  */}

                    <FileUpload ref={fileUploadRef} accept="image/*" customUpload
                    uploadHandler={myUploader} 
                    itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}  headerTemplate={headerTemplate} 
                    
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                  </div>
                  {submitted && !profilePicture && <small className="p-error">Profile Picture is required.</small>}
                
              </div>
              <div className="field">
                <div className="formgrid grid flex align-items-center">
                  <div>
                  <label className="col-8">Category</label>   
                  </div> 
                </div>

                </div>

              <div className="field">
                

                <Dropdown value={albumId} options={cat} optionLabel="name" optionValue="id" onChange={albumidHandler} placeholder="Select a Category. . ." className={classNames({ 'p-invalid': submitted && !albumId })} />
                {submitted && !albumId && <small className="p-error">Category is required.</small>}

             </div>

              <div className="formgrid grid mt-4">
              <div className="col-6">
                <Checkbox inputId="cb1" onChange={e => setRestrictedChecked(e.checked)} checked={reschecked}></Checkbox>
                <label htmlFor="cb1" className="p-checkbox-labe ml-2">Restricted</label>
              </div>
              </div>

              {reschecked && 
                <div className="field m-2">                
                    <label className="col-4">Select user</label>   

                    <div>
                        {/* <MultiSelect filter style={{ width: '100%', zIndex: '1800'}} optionLabel="name"  optionValue="id" value={usersid} options={users} onChange={adduserhandler}  placeholder="Select a user" showClear='true' required={true} maxSelectedLabels={5} 
                        /> */}

                    <MultiSelect
                            style={{ width: '100%', zIndex: '1800' }}
                            optionLabel="name"
                            optionValue="id"
                            value={usersid}
                            options={users}
                            onChange={adduserhandler}
                            placeholder="Select a user"
                            showClear={true}
                            required={true}
                            maxSelectedLabels={0}
                            filter
                            filterTemplate={customHeader}
                            onHide={() => setFilterValue('')} 
                        />
                    </div>
                </div>
                }
          </Dialog>

          <Dialog visible={updateDialog} style={{ width: '450px' }} header="Update Album" modal className="p-fluid" footer={updateDialogFooter} onHide={hideupdateDialog}>
              <div className="field">
                  <label htmlFor="name">Order No.</label>
                  <InputText id="name" keyfilter="int" value={updateorderno} onChange={(e) => setupdateOrderno(e.target.value)} required autoFocus className={classNames({ 'p-invalid': updated && !updateorderno })} />
                  {updated && !updateorderno && <small className="p-error">Order Number is required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="name">Name</label>
                  <InputText id="name" value={updateName} onChange={(e) => setupdatealbumName(e.target.value)} required className={classNames({ 'p-invalid': updated && !updateName })} />
                  {updated && !updateName && <small className="p-error">Name is required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="description">Description</label>
                  <InputTextarea id="description" value={updateDescription} onChange={(e) => setupdatealbumDescription(e.target.value)} required rows={3} cols={20} className={classNames({ 'p-invalid': updated && !updateDescription })} />
                  {updated && !updateDescription && <small className="p-error">Description is required.</small>}

              </div>

              <div className="field">
                  <label className="mb-3">Profile Picture</label> 
                  <div className="formgrid grid flex align-items-center">     
                    <div className='col-12'>
                        {/* <FileUpload name="demo"  mode="basic" customUpload  uploadHandler={myUploader} accept="image/*" />  */}
                        <FileUpload ref={fileUploadRef} accept="image/*" customUpload
                    uploadHandler={myUploader} 
                    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={updateitemTemplate}
                    
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                  {updated && !updateprofilePicture && <small className="p-error">ProfilePicture is required.</small>}

                 
                  </div>
              </div>
              
              

              <div className="field m-2">
                <div className="formgrid grid flex align-items-center">
                  <div>
                  <label className="col-4">Category</label>   
                  </div> 
                  <div className="col-7">
                    {/* {console.log("updatecatid", updatecategoryID)} */}
                  <Dropdown value={updatecategoryID} options={ cat.map((info)=>(info) )}
                
                  onChange={(e) => setupdatecatID(e.value)} optionValue='id' optionLabel='name' className={classNames({ 'p-invalid': updated && !updatecategoryID })}/>
                  </div>
                  
                  {updated && !updatecategoryID && <small className="p-error">Category is required.</small>}

                </div>
              </div>

              <div className="formgrid grid mt-4">
              <div className="col-6">
                <Checkbox inputId="cb1" onChange={e => setupdateres(e.checked)} checked={updateres} ></Checkbox>
                <label htmlFor="cb1" className="p-checkbox-labe ml-2">Restricted</label>
              </div>
              </div>
            
            {
                 updateres &&
                <>
                <div className="field m-2">                
                    <label className="col-4">Select user</label>   

                    <div>
                        {/* <MultiSelect filter style={{ width: '100%', zIndex: '1800'}} optionLabel="name"  optionValue="id" value={updaterestrictedusers} options={users} onChange={adduserhandler}  placeholder="Select a user" showClear='true' required={true} maxSelectedLabels={5} 
                        /> */}

                        <MultiSelect
                            style={{ width: '100%', zIndex: '1800' }}
                            optionLabel="name"
                            optionValue="id"
                            value={updaterestrictedusers}
                            options={users}
                            onChange={adduserhandler}
                            placeholder="Select a user"
                            showClear={true}
                            required={true}
                            maxSelectedLabels={0}
                            filter
                            filterTemplate={customHeader}
                        />
                    </div>
                </div>
                {
                    restrictedUsers && restrictedUsers.length > 0 &&
                    <div>
                    <p>Restricted Users: <span onClick={() => restrictedUsersHandler()} className='link'>view details</span> </p>
                    {/* <ul>
                    {restrictedUsers.map(user => (
                        <li key={user.id}>{user.name}</li>
                    ))}
                    </ul> */}
                    </div>
                }
               </>
            }
          </Dialog>


          <Dialog visible={deleteRestrictedUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteRestrictedUserDialogFooter} onHide={hideDeleteRestrictedUserDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                <span>Are you sure you want to delete?</span>
              </div>
          </Dialog>

          <Dialog visible={restrictedUserDialog} style={{ width: '450px' }} header="Restricted Users List" modal onHide={hideRestrictedUserDialog}>
              <div >
                
                {
                    restrictedUsers && restrictedUsers.length > 0 && 
                    restrictedUsers.map((user,index) => (
                        <>
                        <div className="restricted-content mb-3 mt-3" >
                            <span key={user.id}>{index + 1}. {user.name} - ({user.phoneno})</span>
                            {/* <span key={user.id}></span> */}
                            <i className="pi pi-trash mr-3" style={{ fontSize: '1.3rem', cursor: 'pointer', color: 'red'}} onClick={() => deleteRestrictedUser(user)} />
                        </div>
                        <hr/>
                        </>
                    ))
                 } 


              </div>
          </Dialog>

          {/* <Dialog visible={adduserDialog} style={{ width: '450px'  }} header="Add User" modal className="p-fluid" footer={adduserdialogFooter} onHide={hideadduserDialog}>
            <div className="field m-2">
                    
                    
                <label className="col-4">Select user</label>   
            
                <div>
                    <MultiSelect  style={{ width: '100%', zIndex: '1800'}} optionLabel="name"  optionValue="id" value={usersid} options={users} onChange={adduserhandler}  placeholder="Select a user" showClear='true' required={true} maxSelectedLabels={5} 
                     />
                    
                </div>
                <div>
              {
                <DataTable ref={userdt}  lazy  loading={loading}  value={userslist} responsiveLayout="scroll" >
                    <Column field="name" header="Users List"></Column>
                    <Column body={deleteTemplate} exportable={false}></Column>
                </DataTable>
              }
            </div>
            </div>
         </Dialog> */}


         <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {user && <span>Are you sure you want to delete <b>{user}</b>?</span>}
              </div>
          </Dialog>


          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
              </div>
          </Dialog>


      </div>
      </div>
      </div>
      </div>
      
      </>
  );

  }