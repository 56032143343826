
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Link, useNavigate  } from 'react-router-dom';
import { login } from '../Apicall';


export default function Login(){

const [checked, setChecked] = useState()
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const navigate = useNavigate()
const [clicked, setclicked] = useState(false)
const [failed, setFailed] = useState(false)

const sendLoginRequest = (e) => {
    e.preventDefault();
    setclicked(true)

    login(email,password) 
    .then((response)=> {
        // console.log("Response:",response.status)
        localStorage.setItem('token',response.data.data.accesstoken)
        
        navigate('/');
    })
    .catch( (error) =>{
        // console.log("Error:", error)
        setFailed(true)
    });     
    

}


return(

    <div>
        <div className="flex align-items-center justify-content-center" style={{height:'100vh'}}>
            <div className="surface-card p-4 shadow-2 border-round lg:w-4">
                <div className="text-center mb-3">
                    {/* <img src="assets/images/blocks/logos/hyper.svg" alt="hyper" height={50} className="mb-3" /> */}
                    <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                    <span className="text-600 font-medium line-height-3">Don't have an account?</span>
                    <Link to="#" className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</Link>
                    {/* <a href='#' className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */}
                </div>

                <div>
                    {failed && email && password && <small className="p-error mb-3">Invalid Credentials! Please Try Again </small> }
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email" onChange={e => setEmail(e.target.value)}  type="text" placeholder="Email address" className="w-full" required/>
                    {!email && clicked && <small className="p-error ">EmailId is required.</small>}
                    <label htmlFor="password" className="block text-900 font-medium mb-2 mt-3">Password</label>
                    <InputText id="password" onChange={e => setPassword(e.target.value)}  type="password" placeholder="Password" className="w-full" required/>
                    {!password && clicked &&  <small className="p-error mb-3">Password is required.</small>}
                    <div className="flex align-items-center justify-content-between mb-6 mt-3">
                        <div className="flex align-items-center ">
                            <Checkbox inputId="rememberme" onChange={e => setChecked(e.checked)} checked={checked} className="mr-2" />
                            <label htmlFor="rememberme">Remember me</label>
                            <br/>
                            <br/>
                        

                        </div>
                        <Link to='/forgetpassword' className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot your password?</Link>
                        {/* <a href='/forgetpassword' className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot your password?</a> */}
                    </div>

                    <Button label="Sign In" icon="pi pi-user" onClick={sendLoginRequest} className="w-full" />
                </div>
            </div>
        </div>
    </div>

)}