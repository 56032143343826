import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link  } from 'react-router-dom';


export default function Forgetpassword(){


// const [email, setEmail] = useState('')


return(

    <div>
        <div className="flex align-items-center justify-content-center" style={{height:'100vh'}}>
            <div className="surface-card p-4 shadow-2 border-round lg:w-4.4">
                <div className="text-center mb-5">
                    {/* <img src="pi pi-lock-open" icon="pi pi-lock-open" alt="hyper" height={50} className="mb-3" /> */}
                    <i className="pi pi-key text-600 font-medium line-height-3 m-3 py-1 px-2" style={{fontSize: '1.2em', color:'red', backgroundColor: 'rgb(130,168,205)', borderRadius: '50%' }}></i>
                    <div className="text-bold text-3xl font-medium mb-3  " >Forget Password?</div>
                    <span className="text-600 font-medium line-height-3">No worries, we'll send you reset instructions.</span>
                    {/* <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */}
                </div>

                <div>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText id="email"  type="text" placeholder="Email address" className="w-full mb-3" required/>


                    <Button label="Reset Password" icon="pi pi-lock-open"  className="w-full" />
                    <div className="text-center mt-4">
                    <Link className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
                        <i className="pi pi-arrow-left text-600 font-medium line-height-3" ></i>
                        <Link to="/login"> <span className="text-600 font-medium line-height-3 ml-2    " >Back to log in</span> </Link>
                    </Link>
                    
                    </div>

                </div>
            </div>
        </div>
    </div>

)}