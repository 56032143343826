import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { useNavigate  } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { audioUpload, delepisode, episodedata, imagesignedurl, saveepisode, totalalbumdata, updateepisode, deleteaudiofile, getusers } from '../Apicall';



export default function Episodes() {

    const [loading, setLoading] = useState(true);
    
    const navigate = useNavigate()
    useEffect(() =>{
        if(!localStorage.getItem('token')){
            navigate('/login')
        }
    },[])

    
    let emptyProduct = {
        id: null,
        name: '',
        Profile_pic: null,
        description: '',
        restricted: '',
        category_id: ''
    };


  const [productDialog,   setProductDialog] = useState(false);
  const [updateDialog,   setupdateDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  

  const openNew = () => {
      setProduct(emptyProduct);
      setSubmitted(false);
      setProductDialog(true);
  }

  const hideDialog = () => {
    setepisodePicture('');
    setaudioResponse('');
    setmessage('')
    setaudioFile('')
    setupdatetaudioFile('')
    setSuccess(false)
    setSubmitted(false);
    setProductDialog(false);
      
  }

  const hideupdateDialog = () => {
    setepisodePicture('');
    setmessage('')
    setSuccess(false)
    setUpdated(false);
    setaudioFile('')
    setupdatetaudioFile('')
    setaudioResponse('');
    setupdateDialog(false);
}

  const hideDeleteProductDialog = () => {
      setDeleteProductDialog(false);
  }

  const [users, setUsers] = useState([])
  const [usersid, setUsersID] = useState('')
 
  const saveProduct = () => {
    setSubmitted(true);
        
        const body = {  
            title: episodetitle,
            description: episodeDescription,
            album_id: albumId,
            profile_pic: episodeprofilePicture,
            audiofile: audioFile,
            // preview: prev,
            // restricted: res,
            // premium: plan,
            restrictedusers: usersid ? usersid : null,
            mode: mode,
            latest: latest ? 1 : 0,
            orderno: orderno

        }
        // console.log("bodydata",body);

        if (!episodetitle || !episodeDescription || !albumId || !episodeprofilePicture || !audioFile || !orderno ){
            setProductDialog(true)
        }else{
            saveepisode(body)
            .then((res)=> { 
                // console.log(res);
                datafetch()
                toast.current.show({ severity: 'info', summary: 'Episodes Created', detail: 'Created Sucessfully',life: 3000 });
                setProductDialog(false)
                setmessage('')
                setSuccess(false)
                setaudioFile('')
                setaudioResponse('')
                setupdatetaudioFile('')
            })
            .catch( (err)=> {
                // console.log("saveerr", err);
                setProductDialog(false)

                toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong',life: 3000 });
                setmessage("")
            });    
        }

    setepisodePicture('');
    setmessage('');
    }
  

const [updateorderno, setupdateOrderno] = useState('');
const [updateName, setupdatealbumName] = useState('');
const [updateDescription, setupdatealbumDescription] = useState('');
const [updateprofilePicture, setupdatetprofilePicture] = useState('');
const [updateaudioFile, setupdatetaudioFile] = useState('');
const [updatealbumID, setupdatealbumID] =useState('');
const[updateid,setupdateId] = useState();
const [updatedisplayname, setupdatedisplayName] = useState();


const [updatemode, setUpdateMode] = useState()

const [updatelatest, setUpdateLatest] = useState()

// console.log("updatestate", updatelatest);




useEffect(() => {
    albums.map((albumname)=>{
        

        if(albumname.id === updatealbumID){
             
            setupdatedisplayName(albumname.name)
        }
    
    })
}, [updatealbumID]) 

const editProduct = (id) => {
//   console.log("episode edit dataaaa", id);
    setUpdated(false)
    
    setupdateDialog(true);
    setupdatealbumName(id.title)
    setupdatealbumDescription(id.description)
    setupdatetprofilePicture(id.profile_pic) 
    setupdatetaudioFile(id.audiofile)
    setupdatealbumID(id.album_id)
    setUpdateMode(id.mode)
    setupdateusersid(id.restrictedusers)
    id.latest === 1 ? setUpdateLatest(true) : setUpdateLatest(false)
    setupdateOrderno(id.orderno)
    setupdateId(id)
}

const [updateusersid, setupdateusersid] = useState("")

const updateuserhandler = (e) =>{
    setupdateusersid(e.target.value)
}

const updateProduct = () =>{
    
    setUpdated(true)
    
    var body1 = {
        title: updateName,
        description:  updateDescription,
        album_id: updatealbumID,
        profile_pic: updateprofilePicture,
        audiofile: updateaudioFile,
        restrictedusers: updatemode === 1 ? updateusersid : null,
        mode: updatemode,
        latest: updatelatest ? 1 : 0,
        orderno: updateorderno,
        id: updateid.id
    
    }
// console.log("restriced body", body1);

    if (!updateName || !updateDescription || !updatealbumID || !updateprofilePicture || !updateaudioFile || !updateorderno ){
        setupdateDialog(true)
    }else{
    
    updateepisode(body1)
    .then(function (response) {
        
        
        datafetch()
        toast.current.show({ severity: 'info', summary: 'Episode Updated', detail: 'Update Sucessfully',life: 3000 });
        
        setupdateDialog(false);
        setmessage('')
        setSuccess(false)
        setaudioFile('')
        setupdatetaudioFile('')
        setaudioResponse("")
        
    })
    .catch(function (error) {
        // console.log(error);
        setupdateDialog(false);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

        // console.log(error)
    });      
}
    // setUpdated(true)s
    
} 

  const [albumdeleteid, setDeleteId] = useState()
  const confirmDeleteProduct = (product) => {
      setProduct(product);
      setDeleteId(product)
      setDeleteProductDialog(true);
  }

  const deleteProduct = () => {
    setUpdated(true)
    var body = {  
        id: albumdeleteid.id
    }
    delepisode(body)
    .then(function (response) {

        // console.log(response);
        // setDeleteMsg(response)
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Episode Deleted', life: 3000 });
        datafetch()
        // history.pushState('/category')
    })
    .catch(function (error) {
        // console.log(error);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

    });      
    // if(!updated && !updateName){
    //     console.log("hos")
    //     setProductDialog(true)
    // }else{
    //     // console.log('jkghs')
    //     setProductDialog(false);    
    // }
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    

  }


  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
              {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
          </React.Fragment>
      )
  }


  const rightToolbarTemplate = () => {
    return (
        <React.Fragment>

            <Dropdown value={showalbumId} selected={albumid} options={albums} optionLabel="name" style={{width:'235px'}} optionValue="id" onChange={showlbumidHandler} placeholder="Select a Album. . ." />
            
        </React.Fragment>
    )
}

  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={() => confirmDeleteProduct(rowData)} />
        </React.Fragment>
    );
}

  const header = (
      <div className="table-header">
          <h5 className="mx-0 my-1">Manage Episodes</h5>
          {/* <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span> */}
      </div>
  );
  const productDialogFooter = (
      <React.Fragment>
        <div className='mt-0'>
          <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </div>
      </React.Fragment>
  );

  const updateDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideupdateDialog} />
        <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
      </div>
    </React.Fragment>
);

  const deleteProductDialogFooter = (
      <React.Fragment>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
      </React.Fragment>
  );
  

  const [count, setCount] = useState([]);
  const [episode, setEpisode] = useState([]); 
  const [pages, settotalPages] = useState(0);
  const [picUrl, setPicUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
console.log("episodes", episode);
   const datafetch = useCallback(async() => {
     setLoading(true);

     setTimeout(() => {
        setLoading(false);
     }, 3000);
    
    episodedata (showalbumId, pages) 
    .then((res) => {
        
        setPicUrl(res.data.pic_url);
        setAudioUrl(res.data.audio_url);
        setEpisode(res.data.data.rows);
        setCount(res.data.data.count);
        setLoading(false);
    })
    .catch((err) => {
        // console.log("episode err",err );
        if(err.message === "Network Error"){
            // alert("Please check your network connection")
        }
        if(err.response && err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        });
    
  })


  useEffect(()=>{
    datafetch()
  },[pages])


  const imageBodyTemplate = (rowData) => {
    // console.log( "profilepictureurl", picUrl+rowData.profile_pic);
    
    return <img src={picUrl+rowData.profile_pic}  className="product-image" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.profile_pic} />
  
}

const modeBodyTemplate = (rowData) =>{
  
    if(rowData.mode === 1){
        return <Tag severity="danger" value="Restricted"></Tag>
    }
    if(rowData.mode === 2){
        return <Tag severity="success" value="Premium"></Tag>
    }
    if(rowData.mode === 3){
       return <Tag severity="info" value="Preview"></Tag>
    }
    
}
 
//   Add episodes

const [orderno, setOrderno] = useState("");
const [episodetitle, setepisodeName] = useState('');
const [episodeDescription, setepisodeDescription] = useState('');
const [episodeprofilePicture, setepisodePicture] = useState('');
const [audioFile, setaudioFile] = useState('');


// const [prev,setPreview] = useState('');
// const [res, setres] = useState('');
// const [plan, setPlan] =useState('');
const [mode, setMode] = useState()

const [latest, setLatest] = useState()


const [albums, setAlbums] = useState([]); 


const totalalbums = useCallback(async() =>{
    await totalalbumdata () 
    .then((res) => {
        // console.log("total album data", res.data.data);
        setAlbums(res.data.data);
    })
    .catch((err) => {
        // console.log("api error", err);
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        });
  
})

useEffect(()=>{
    totalalbums()
},[])


const [albumId, setalbumvalue] = useState()


useEffect (()=>{
    setupdatealbumID(albumId);
},[albumId])

useEffect(()=>{
    albums && albums.map((data)=>{
        if(data.id === albumId){
            if(data.restricted === 1 ){
                setMode(1)
               
            }else{
                setMode()
            } 
        }
    })
},[albumId])



const albumidHandler = (e) =>{
    setalbumvalue(e.value)   
    
}

const [defaultalbumid, setdefaultalbumid] = useState('')

useEffect(()=>{
    setdefaultalbumid(albums.map((id)=>(id.id)))
},[albums])

const albumid = defaultalbumid[defaultalbumid.length-1]

// console.log('defaultid',defaultalbumid)
// console.log('albumid',albumid)

const [showalbumId, showalbumvalue] = useState(albumid)
// console.log('showalbumId',showalbumId)


useEffect (()=>{

    datafetch()
   
},[albumid])



const showlbumidHandler = (e) =>{
    showalbumvalue(e.value)   
    setalbumvalue(e.value)
    
}

useEffect (()=>{
    datafetch()
},[showalbumId])

    const myUploader = async (file) =>{
    
        const today = new Date(),
        date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString() + today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
        const name = file.files[0].name
        const filename = name.split(".")[0]+"_"+date+"."+name.split(".")[1]
        // console.log("filenam,eimae", filename);
        const body = {
            locae: 'episode',
            fileName: filename,
            fileType: file.files[0].type            
        }

        imagesignedurl(body)
        .then((response)=> {
            // console.log(response)

            setepisodePicture(filename)
            setupdatetprofilePicture(filename)
            setmessage("File Uploading...")



            const uploadimg = () =>{

                return new Promise((resolve, reject) => {
              
                    try{
            
                        const result = fetch(response.data.url, {
                
                            method: "PUT",
                            headers:{
                                "Content-Type": file.files[0].type,
                                
                            }, 
                            body: file.files[0],
                            onUploadProgress: event => {
                                setProgress(Math.round(100 * event.loaded) / event.total)
                            }
                            });
                        resolve(result)
                        // console.log("result", result)
                        // setaudioResponse(result)
                
                        }
                        catch(e){
                            // console.log("error",e)
                            reject(e)
                        }

              });
              
              }

              uploadimg().then((res)=>{
                    // console.log("imageres", res);
                    setmessage("File Uploaded Successfully")
                    setSuccess(true)
              })
              .catch((err)=>{
                console.log("imageer", err);
                setmessage("Upload Failed")
              })
            
        })
        .catch( (error) =>{
            // console.log(error)
        });      
        
        
    }

// audioFile upload function
const [audiofileResponse, setaudioResponse] = useState("");
const [progress, setProgress] = useState(0);



    const myaudioUploader = async (file) =>{
    
        const today = new Date(),
        date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString() + today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
        const name = file.files[0].name
        const filename = name.split(".")[0]+"_"+date+"."+name.split(".")[1]
        const body = {
            fileName: filename,
            fileType: file.files[0].type,
            id: albumId
        }
        
        audioUpload(body)
        .then(async(response)=> {
            // console.log(response)
            // seturl(response.data.url)
            setaudioFile(filename)
            setupdatetaudioFile(filename)

            const formdata = new FormData()





            formdata.append('file', file.files[0])

            const uploadaudio = () =>{
                    
                return new Promise((resolve, reject) => {

                    try{
            
                        const result = fetch(response.data.url, {
                
                            method: "PUT",
                            headers:{
                                "Content-Type": file.files[0].type,
                                
                            }, 
                            body: formdata,
                            

                            });
                        resolve(result)
                        // console.log("result", result)
                        // setaudioResponse(result)
                
                        }
                        catch(e){
                            // console.log("error",e)
                            reject(e)
                        }

              });
              
              }

              uploadaudio().then((res)=>{
                    // console.log("auidoresss", res);
                    setaudioResponse("File Upload Successfully")
              })
              .catch((err)=>{
                console.log("auidoerrr", err);
                setaudioResponse("File Upload Failed")
              })
            
        })
        .catch( (error) =>{
            console.log(error)
            setaudioResponse("File Upload Failed")
        });      
    }

    
      


    const [first1, setFirst1] = useState(0);
   
    const onCustomPage1 = (event) => {
        setFirst1(event.first);
       
    }
   
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Previous</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
          
            
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Next</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            // console.log(options)
            settotalPages(options.currentPage)
            // console.log('pages',pages)
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
      
    };
   

const [message, setmessage] = useState('')
const [success, setSuccess] = useState(false)

const fileUploadRef = useRef(null);


    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        // const value = totalSize/10000;
        
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center',height:'63px'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    }


    


    const updateitemTemplate = (file, props) => {
        return (
            <>
        
            <div className="flex align-items-center flex-wrap p-1" >
                <div className="flex align-items-center" style={{width: '100%', marginLeft: '-12px', marginTop: '-10px'}}>
                    <img alt={file.name} role="presentation" src={picUrl+updateprofilePicture} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name} 
                        
                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                    </span>
                    <span className="flex flex-column text-left ml-3">
                        {/* {props.formatSize} */}
                    </span>
                     

                    <div>	
                        
                        { success?
                                <div > 
                                <Tag icon="pi pi-check m-1" severity="warning" className="p-button-warning p-button-rounded p-button-fill mt-1 ml-4 px-1 py-1"  style={{ borderRadius: '50%'}} />
                                </div>
                                        
                                
                                    :<p>{}</p>
                                    
                                }
                    </div>

                </div>
        
                {
                 message?                    
                    <span className='small mt-1' style={{marginBotton: '-10px', marginLeft: '-10px',color:'grey'}}>{message}</span>
                    :<p>{}</p>
                }
                
                

            </div>
            
            </>
        )
    }


    const itemTemplate = (file, props) => {
        return (
            <>
        
            <div className="flex align-items-center flex-wrap p-1" >
                <div className="flex align-items-center" style={{width: '100%', marginLeft: '-12px', marginTop: '-10px'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name} 
                        
                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                    </span>
                    <span className="flex flex-column text-left ml-3">
                        {props.formatSize} 
                    </span>
                     

                    <div>
                        {  success?
                                <div > 
                                <Tag icon="pi pi-check m-1" severity="warning" className="p-button-warning p-button-rounded p-button-fill mt-1 ml-4 px-1 py-1"  style={{ borderRadius: '50%'}} />
                                </div>
                                        
                                
                                    :<p>{}</p>
                                    
                                }
                    </div>

                </div>
        
                {
                    message?
                    <span className='small mt-1' style={{marginBotton: '-10px', marginLeft: '-10px',color:'grey'}}>{message}</span>
                    :<p>{}</p>
                }
                
                

            </div>
            
            </>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{height: '70px'}}>
                <i className="pi pi-image" style={{fontSize: '5em', borderRadius: '50%',marginTop: '-20px', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{fontSize: '1.2em', color: 'var(--text-color-secondary)'}} className="m-1">Drag and Drop Image Here</span>
            </div>
        )
    }

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined p-2 '};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined p-2'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined p-2'};

    
    const AudioTemplate = (url) => {
    console.log("audio url", audioUrl+url.audiofile);
   
    const audioref = useRef(null);
  
    useEffect(()=>{
        audioref.current.load();
    },[url.audiofile])
    return (
        <>
             <audio controls id="myVideo" ref={audioref} preload  >
                <source  src={audioUrl+url.audiofile}  />
                Browser does not support Audio File
             </audio>
             
        </>
      );
    }

    
const [audioresponse,setAudiodeleteres] = useState(false)
const AudioDialogTemplate = () => {
   
    return (
        
        <>
            { console.log("updateprofile audio fileee inner", updateaudioFile) }
            <audio controls id="myVideo" preload  >
                <source  src={audioUrl+updateaudioFile}   />
                Browser does not support Audio File
            </audio>
        </>
        );
}

const audiobtn = () =>{
    if(audiofileResponse){
        return(<p>{audiofileResponse}</p>)
    } else if(audioFile){
        return(

        <div className="formgrid grid mt-1 flex align-items-center">
            <div className="col-2">
                <ProgressSpinner style={{width: '30px', height: '30px'}}/>
            </div>
            <div className="col-4">
                <p >File Uploading</p>
                {progress > 0 && <progress value={progress} max="100" />}
                {progress > 0 && <span>{`${progress}%`}</span>}
            </div>
            
        </div >
        )
    }else{
        return('')
    }
}


  const adduserhandler = (e) =>{
    setUsersID(e.value)
    
   }

   const getuser = useCallback(async() => {
    getusers () 
    .then((res) => {
        // console.log('res ' , res)
        setUsers(res.data.data)
     
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        });
  
  })

  useEffect(()=>{
    getuser()
  },[])

  const deleteaudio = async() =>{

    const body = {
        filename: updateaudioFile,
        albumid: updatealbumID,
        episode_id: updateid.id
    }
   
    deleteaudiofile(body)
    .then((res)=>{
        console.log("audio file res", res);
        setAudiodeleteres(true);
        setupdatetaudioFile("");
    })
    .catch((err)=>{
        console.log("audio file errr", err);
    })
  }

  const audioupload = (event) =>{
    console.log("audio event", event.target.value)
  }

return (
    <>
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

              <DataTable ref={dt} lazy  loading={loading} value={episode} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id" paginator onPage={onCustomPage1}
                  paginatorTemplate={template1} first={first1} rows={10} totalRecords={count} 
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Episodes"  
                  globalFilter={globalFilter} header={header} responsiveLayout="scroll"  >
            
            
                  <Column field="title"  header="Title" style={{ minWidth: '8rem' }}></Column>
                  <Column field="description" header="Description" style={{ minWidth: '12rem' }}></Column>
                  <Column field="profile_pic" header="Profile Pic" body={imageBodyTemplate}></Column>
                  <Column field="audiofile" header="Audio" alignHeader='center' body={AudioTemplate} style={{ minWidth: '3rem' }} ></Column>
                  <Column field="mode" header="Mode" body={modeBodyTemplate} className='text-center' style={{ minWidth: '2rem' }}></Column>
                  <Column field="orderno" header="Order" className='text-center' style={{ minWidth: '2rem' }}></Column>
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
          </div>

          <Dialog visible={productDialog} style={{ width: '450px' }} header="Create Episode" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
              
            <form method='post'>
                <div className="field">
                    <label htmlFor="order">Order No.</label>
                    <InputText id="order" keyfilter="int" value={orderno} onChange={(e) => setOrderno(e.target.value)} required={true} autoFocus className={classNames({ 'p-invalid': submitted && !orderno })} />
                    
                    {submitted && !orderno &&<small className="p-error">Order is required.</small>}
                </div>
              <div className="field">
                  <label htmlFor="name">Title</label>
                  <InputText id="name" value={episodetitle} onChange={(e) => setepisodeName(e.target.value)} required={true} className={classNames({ 'p-invalid': submitted && !episodetitle })} />
                  
                  {submitted && !episodetitle &&<small className="p-error">Name is required.</small>}
              </div>

              <div className="field">   
                  <label htmlFor="description">Description</label>
                  <InputTextarea id="description" value={episodeDescription} onChange={(e) => setepisodeDescription(e.target.value)} required={true} rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !episodeDescription })} />
              
                  {submitted && !episodeDescription &&<small className="p-error">Description is required.</small>}
              </div>

              <div className="field">
                  <label className="mb-3">Profile Picture</label> 
                  <div className="formgrid grid flex align-items-center">     
                    <div className='col-12'>
                        <FileUpload ref={fileUploadRef} accept="image/*" customUpload
                        uploadHandler={myUploader} 
                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                        
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                  </div>
                  {submitted && !episodeprofilePicture &&<small className="p-error">Please upload the profile picture.</small>}
              </div>
              
              <div className="field">
   
                  <label>Album</label>   
                 
                <Dropdown  value={albumId} options={albums} optionLabel="name" optionValue="id" onChange={albumidHandler} required={true} placeholder="Select a Album"/>
                {submitted && !albumId &&<small className="p-error">Album is required.</small>}
              </div>

              <div className="field">
                  <label className="mb-3">Upload Audio</label>      
                    <FileUpload onChange={audioupload} name="demo"  mode="basic" accept=".mp3" customUpload  uploadHandler={myaudioUploader} onClick={audiobtn}  /> 
                    { audiobtn() }
                    {submitted && !audioFile &&<small className="p-error">Please upload the audio.</small>}
              </div>
              

              <div className="formgrid grid mt-4">

                    <div className="flex align-items-center col-4">
                        <RadioButton inputId="mode1" name="mode" value={1} onChange={(e) => setMode(e.value)} checked={mode === 1} />
                        <label htmlFor="mode1" className="ml-2">Restricted</label>
                    </div>
                    <div className="flex align-items-center col-4">
                        <RadioButton inputId="mode2" name="mode" value={2} onChange={(e) => setMode(e.value)} checked={mode === 2} />
                        <label htmlFor="mode2" className="ml-2">Premium</label>
                    </div>
                    <div className="flex align-items-center col-4">
                        <RadioButton inputId="mode3" name="mode" value={3} onChange={(e) => setMode(e.value)} checked={mode === 3} />
                        <label htmlFor="mode3" className="ml-2">Preview</label>
                    </div>
                    {submitted && !mode &&<small className="p-error mt-2 ml-2">Select the value</small>}
                    
              </div>
               { mode===1 && 
                    <div className="field mt-3" >
                    
                        <div>
                        <label>Select user</label>   
                        
                        <MultiSelect className='mt-2'  style={{ width: '100%', zIndex: '1800'}} optionLabel="name"  optionValue="id" value={usersid} options={users} onChange={adduserhandler}  placeholder="Select a user" showClear='true' required={true} maxSelectedLabels={5} />
                        
                        </div>
                    </div>
               }

                <div className="field mt-3">
                    <div className="flex align-items-center">
                        <Checkbox inputId="cb4" onChange={e => setLatest(e.checked)} checked={latest}></Checkbox>
                        <label htmlFor="cb4" className="ml-2">Latest</label>
                    </div>
                </div>
            </form>
          </Dialog>

          <Dialog visible={updateDialog} style={{ width: '450px' }} header="Update Episode" modal className="p-fluid" footer={updateDialogFooter} onHide={hideupdateDialog}>
              {product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />}
              
              <div className="field">
                  <label htmlFor="name">Order No.</label>
                  <InputText id="name" keyfilter="int" value={updateorderno} onChange={(e) => setupdateOrderno(e.target.value)} required autoFocus className={classNames({ 'p-invalid': updated && !updateorderno })} />
                  {updated && !updateorderno && <small className="p-error">Order Number is required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="name">Name</label>
                  <InputText id="name" value={updateName} onChange={(e) => setupdatealbumName(e.target.value)} required={true} className={classNames({ 'p-invalid': updated && !updateName })} />
                  {updated && !updateName && <small className="p-error">Name is required</small>}
              </div>
              <div className="field">
                  <label htmlFor="description">Description</label>
                  <InputTextarea id="description" value={updateDescription} onChange={(e) => setupdatealbumDescription(e.target.value)} required={true} rows={3} cols={20}  className={classNames({ 'p-invalid': updated && !updateDescription })}  />
                  {updated && !updateDescription && <small className="p-error">Description is required</small>}
              </div>
              <div className="formgrid grid mt-4 flex align-items-center justify-content-center">
                <div className="col-12">
                    <div className="field">
                        <label className="mb-3">Profile Picture</label>      
                            {/* <FileUpload name="demo"  mode="basic" customUpload  uploadHandler={myUploader}  />  */}
                        <FileUpload ref={fileUploadRef} accept="image/*" customUpload
                            uploadHandler={myUploader} 
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={updateitemTemplate}
                            
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                            
                    </div>
                </div>
                {updated && !updateprofilePicture && <small className="p-error">Please upload the Profile Picture.</small>}
              </div>

              <div className="field">
                
                <label >Select Album</label>   
                <Dropdown value={albumId} options={albums} optionLabel="name" optionValue="id" onChange={albumidHandler} placeholder={updatedisplayname} className={classNames({ 'p-invalid': updated && !albumId })}  />
                {updated && !albumId && <small className="p-error">Album is required</small>}
                
              </div>
              
              <div className="field">
                  <label className="mb-3">Upload Audio</label>      
                      <FileUpload name="demo"  mode="basic" accept=".mp3" customUpload  uploadHandler={myaudioUploader} onClick={audiobtn} /> 
                      { audiobtn() }
                    <div className='mt-3 flex align-items-center'>
                        { updateaudioFile && 
                            <>
                                {AudioDialogTemplate() }
                                
                                <Button icon="pi pi-times" className="p-button-rounded p-button-warning ml-2" onClick={deleteaudio}  />
                                
                            </>
                        }
                    </div>
                {updated && !updateaudioFile && <small className="p-error">Please uplaod the audio File</small>}
              </div>

              <div className="formgrid grid mt-4">

                    <div className="flex align-items-center col-4">
                        <RadioButton inputId="mode1" name="mode" value={1} onChange={(e) => setUpdateMode(e.value)} checked={updatemode === 1} />
                        <label htmlFor="mode1" className="ml-2">Restricted</label>
                    </div>
                    <div className="flex align-items-center col-4">
                        <RadioButton inputId="mode2" name="mode" value={2} onChange={(e) => setUpdateMode(e.value)} checked={updatemode === 2} />
                        <label htmlFor="mode2" className="ml-2">Premium</label>
                    </div>
                    <div className="flex align-items-center col-4">
                        <RadioButton inputId="mode3" name="mode" value={3} onChange={(e) => setUpdateMode(e.value)} checked={updatemode === 3} />
                        <label htmlFor="mode3" className="ml-2">Preview</label>
                    </div>

                </div>
                { updatemode === 1 && 
                    <div className="field mt-3">
                        <div>
                            <label>Select user</label>   
                            
                            <MultiSelect className='mt-2'  style={{ width: '100%', zIndex: '1800'}} optionLabel="name"  optionValue="id" value={updateusersid} options={users} onChange={updateuserhandler}  placeholder="Select a user" showClear='true' required={true} maxSelectedLabels={5} />
                        
                        </div>
                    </div>
               }
                <div className="col-4 mt-2">
                    <Checkbox inputId="cb4" onChange={e => setUpdateLatest(e.checked)} checked={updatelatest}></Checkbox>
                    <label htmlFor="cb4" className="p-checkbox-labe ml-2">Latest</label>
                </div>
            
          </Dialog> 



          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {product && <span>Are you sure you want to delete <b>{product.title}</b>?</span>}
              </div>
          </Dialog>

          
      </div>
      </div>
      </div>
      </div>
      </>
  );

}

