import React, { useEffect } from 'react';
import './home.css';
import Sidebar from './sidebar/Sidebar';
import Navbar from './navbar/Navbar';
import Homecontent from './homecontent/Homecontent';
import { useNavigate  } from 'react-router-dom';


export default function Home(){

   
    const navigate = useNavigate()

    useEffect(() =>{
        if(!localStorage.getItem('token')){
            // return <Navigate to ="/login"  />
            
            navigate('/login')
        }
    },[])

    return(
        <>
            <div style={{display:'flex'}}>
                <div>
                    <Sidebar />
                </div>
                <div style={{width:'100%'}}>
                    <Navbar/>   
                    <div className='content'>
                        <Homecontent/>
                    </div>
                </div>
                

                
            </div>
            
            {/* <div className='topbar'>
                <div className='topbar-logo'>
                    <h3>Welcome</h3>
                </div>
                <div className='toggle-btn'>
                    <i className="pi pi-align-justify" onClick={()=>setVisible(true)}  ></i>
                </div>
                <div className='topbar-icon'>
                    <i className="pi pi-bell mr-3" style={{'fontSize': '1em'}}></i>
                    <i className="pi pi-user"></i>
                </div>
                
            </div>
            <TieredMenu model={sidebarItems} style={{border: 'none',width: '20%', height:'400px'}} /> */}

            
            
        </>
    
        )
};

    