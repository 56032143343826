import React, { useState, useEffect, useRef  } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { addslide, getsliderimages, sliderimage, sliderimagedelete } from '../Apicall';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
        
export default function Addsubscribers() {

    const navigate = useNavigate();

    const toast = useRef(null);
    const fileUploadRef = useRef(null);
    const dt = useRef(null);

    const [imageDialog,  setImageDialog] = useState(false);

    const openNew = () => {
        setImageDialog(true)
    }

    const hideDialog = () => {
        setImageDialog(false);
    }

    const imageDialogFooter = (
        <React.Fragment>
          <div className='mt-0'>
             {/* <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} /> */}
            {/* <Button label="Upload" icon="pi pi-upload" className="p-button-text" onClick={sliderUploadImage} /> */}
          </div>
        </React.Fragment>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Upload File" icon="pi pi-upload" className="p-button-success mr-2" onClick={openNew} />
                
            </React.Fragment>
        )
    }

    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const [ sliderfilename ,setSliderFileName] = useState('')
    const [ sliderfileid ,setSliderFileID] = useState('')

    const deleteSliderImage = () =>{
        const body ={
            filename: sliderfilename,
            locae: 'slides',
            id: sliderfileid
        }
        
       sliderimagedelete(body)
       .then((res)=>{
            // console.log("resdel", res);
            sliderImage()
            setDeleteProductDialog(false)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Image Deleted', life: 3000 });

       })
       .catch((err)=>{
        console.log("deleterrr", err);
        setDeleteProductDialog(false)
        toast.current.show({ severity: 'warning', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
       })

    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSliderImage} />
        </React.Fragment>
    );

    const confirmDeleteProduct = (data) => {
        // console.log("deletedata", data);
        const filename = data && data.filename.substring(data.filename.lastIndexOf('/')+1);
        setSliderFileName(filename)
        setSliderFileID(data.id)
        setDeleteProductDialog(true);
    }


    const [sliderimageurl, setImageFileUrl] = useState('')
    const [message, setmessage] = useState('')
    const [success, setSuccess] = useState('')
    

    const myUploader = async (file) =>{
       
    
        const today = new Date(),
        date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString() + today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
        const name = file.files[0].name
        const filename =  name.split(".")[0]+"_"+date+name.substring(name.lastIndexOf("."))
        console.log("filenam,eimae", filename);
        const body = {
            locae: 'slide',
            fileName: filename,
            fileType: file.files[0].type            
        }
        sliderimage(body)
        .then((response)=> {
            // console.log("upload response",response)
            // seturl(response.data.url)
            // console.log('albumminsidfeurl',response.data.url)

            setImageFileUrl(response.data.fileurl)
            // setmessage("File Uploading...")
            // setupdatetprofilePicture(filename)

            // console.log('fileURLLLLL', response.data.fileurl)

            const uploadimg = () =>{

                return new Promise((resolve, reject) => {
              
                    try{
            
                        const result = fetch(response.data.url, {
                
                            method: "PUT",
                            headers:{
                                "Content-Type": file.files[0].type,
                                
                            }, 
                            body: file.files[0],
                            
                            });
                        resolve(result)
                        // console.log("result", result)
                        // setaudioResponse(result)
                
                        }
                        catch(e){
                            // console.log("error",e)
                            reject(e)
                        }
              });
            }

            uploadimg().then((res)=>{
                // console.log("imageres", res);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Image Upload Successfully', life: 3000 });
                setmessage("File Upload Successfully")
                const addslidebody = {
                    key : filename
                }
                addslide(addslidebody).then((res)=>{
                   
                console.log("add slide response", res );
                }).catch((err)=>{
                    console.log("add slide err", err);
                })

                sliderImage()
                setSuccess(true)
                })
                .catch((err)=>{
                    // console.log("imageer", err);
                    // setImageDialog(true)
                    setmessage("File Upload Failed")
                })
            
        })
        .catch( (error) =>{
            // console.log(error)
        });      
    }




    const [imagedata, setImageData] = useState([])
// console.log("image data", imagedata);
    const sliderImage = (()=>{
        getsliderimages()
        .then((res)=>{
           
            res.data ? setImageData(res.data.data) : setImageData()
            
        })
        .catch((err)=>{
            
            if(err.response.status === 401){
                localStorage.removeItem('token')
                navigate("/");
            }

        })
    })

    useEffect(()=>{
        sliderImage()
    },[])


    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        // const value = totalSize/10000;
        
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center',height:'63px'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                
            </div>
        );
    }

    const itemTemplate = (file, props) => {
        return (
            <>
        
            <div className="flex align-items-center flex-wrap p-1" >
                <div className="flex align-items-center" style={{width: '100%', marginLeft: '-12px', marginTop: '-10px'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name} 
                        
                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                    </span>
                    <span className="flex flex-column text-left ml-3">
                        {props.formatSize} 
                    </span>
                     

                    <div>
                        {  success?
                                <div > 
                                <Tag icon="pi pi-check m-1" severity="warning" className="p-button-warning p-button-rounded p-button-fill mt-1 ml-4 px-1 py-1"  style={{ borderRadius: '50%'}} />
                                </div>
                                        
                                
                                    :<p>{}</p>
                                    
                                }
                    </div>

                </div>
                <div>
                {   
                    imageuplaodresponse()
                }
                </div>

            </div>
            
            </>
        )
    }

    const  emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{height: '70px'}}>
                <i className="pi pi-image" style={{'fontSize': '5em', borderRadius: '50%',marginTop: '-20px', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="m-1">Drag and Drop Image Here</span>
            </div>
        )
    }

    const onTemplateClear = () => {
        setSuccess('')
        setmessage('')
        setImageFileUrl('')
    };

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined p-2 '};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined p-2'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined p-2'};


    const actionBodyTemplate = (rowData) => {
        // console.log("deletedatya", rowData);
        return (
            <React.Fragment>
               
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={() => confirmDeleteProduct(rowData)}  />
            </React.Fragment>
        );
    }


    const imageBodyTemplate = (rowData) => {
        // console.log("rowdatata...", rowData);


        // production url
        // const formatType = rowData && rowData.substring(rowData.lastIndexOf(".")+1).toString()

        // development url
        const formatType = rowData && rowData.filename.substring(rowData.filename.lastIndexOf(".")+1).toString()

        // console.log("formatType",  rowData.filename.substring(rowData.filename.lastIndexOf(".")+1).toString());
     

        if(formatType === "mp4" ||formatType === "mov" || formatType === "wmv" || formatType === "avi" ||formatType ===  "mkv" || formatType === "webm" || formatType === "flv" || formatType === "ogg" )
        {
        
            return(
                <video width="290" height="180" controls >
                <source src={rowData} type="video/mp4" />  
                Your browser does not support the video tag.
                </video>
            )
        }
        else{
            return(
            
                <img src={rowData.filename} className="product-image" alt='semmai-sev' onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}  /> 
                )
        }

}


    const imageuplaodresponse = () =>{
        if(message){
            return(<p>{message}</p>)
        } else if(sliderimageurl){
            return(
    
            <div className="formgrid grid mt-1 flex align-items-center">
                <div className="col-2">
                    <ProgressSpinner style={{width: '30px', height: '30px'}}/>
                </div>
                <div className="col-3 ml-2">
                    <p>File Uploading</p>
                </div>
                
            </div >
            
            
            )
        }else{
            return('')
        }
    }





    return (
      <>
        <div style={{display:'flex'}}>
        <div>
            <Sidebar />
        </div>
        <div style={{width:'85%'}}>
            <Navbar/>   

        <div className='content'>
        <div className="datatable-crud-demo m-2">
            <Toast ref={toast} className='toast' />

            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                
                <DataTable ref={dt} value={imagedata} 
                  dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Images"
                  responsiveLayout="scroll">

                    <Column style={{ textAlign:'center' }} header="Slider Data" body={imageBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} ></Column>
              
              </DataTable>

            </div>

          <Dialog visible={imageDialog} style={{ width: '450px' }} header="Upload Slider Image" modal className="p-fluid" footer={imageDialogFooter} onHide={hideDialog}>
              {/* {product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />} */}  
            <form method='post'>
              

              <div className="field">
                <label className="mb-3">Slider Image Uplaod</label> 
                  <div className="formgrid grid flex align-items-center">     
                    <div className='col-12'>
                       

                       <FileUpload ref={fileUploadRef} customUpload
                        uploadHandler={myUploader} 
                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} onClear={onTemplateClear}
                        
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
     
                        
                    </div>
                   
                    
              </div>
              </div>
             
            </form>
          </Dialog>

          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {<span>Are you sure you want to delete?</span>}
              </div>
          </Dialog>
            
        </div>
        </div>
        </div>
        </div>
      </>
      );
}

