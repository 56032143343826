import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate  } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { exportdata, addsubscribers, deletesubscribers, getpricingdata, getsubscribers, getuser, searchsubscriber, updatesubscribers, getusers } from '../Apicall';
import { Menu } from 'primereact/menu';
// import ExportExcel from '../exportexcel/Exportexcel';


import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export default function Addsubscribers() {

    const exportmenu = useRef(null);


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const printdata = []

    const exportToExcel = async () =>{
        
        const ws = XLSX.utils.json_to_sheet(printdata);
        const wb = { Sheets: {'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "ExportDataFile" + fileExtension);
    }

    const exportapi = (id) =>{
        exportdata(id)
        .then((res)=>{
            res.data.data.map( async (data, index) => {
                const phoneno = [data.User.countrycode,data.User.phoneno];
                
                printdata.push({"Username": data.User.name, "Phone Number": phoneno.toString(), "Email ID": data.User.email, "Start Date": data.start_date, "End Date": data.end_date })
               
            })
            exportToExcel("ExportFile")

        })
        .catch((err)=>{
            // console.log("exportdataerr", err);
        })
    }   

    const exportfunction = async(data) =>{
        exportapi(data)
    }
    

    const items = [
        {
            label: 'Export Options',
            items: [
                {
                    label: 'All Data',
                    icon: 'pi pi-external-link',
                   
                    command: () => exportfunction(0)
                    
                },
                {
                    label: 'Due for expiration',
                    icon: 'pi pi-external-link',
                    command: () => exportfunction(1)
                },
                {
                    label: 'Expired',
                    icon: 'pi pi-external-link',
                    command: () => exportfunction(2)
                    
                }
            ]
        },
      
    ];

const navigate = useNavigate()
    
const [productDialog,   setProductDialog] = useState(false);
const [updateDialog,   setupdateDialog] = useState(false);
const [deleteProductDialog, setDeleteProductDialog] = useState(false);
const [selectedProducts, setSelectedProducts] = useState(null);
// const [submitted, setSubmitted] = useState(false);
// const [updated, setUpdated] = useState(false);
const [globalFilter, setGlobalFilter] = useState(null);
const toast = useRef(null);
const dt = useRef(null);

// console.log(submitted, updated);



const openNew = () => {
    // setProduct(emptyProduct);
    // setSubmitted(false);
    setProductDialog(true);
    setStartDate(new Date())
}

const hideDialog = () => {
    // setSubmitted(false);
    setProductDialog(false);
    setaddusersubcribers("")
    setStartDate("")
    setEndDate("")
    setUpdatechangeenddate(null)
}
const hideupdateDialog = () => {
    // setUpdated(false);
    setupdateDialog(false);
    setUpdatechangeenddate(null)
}

const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
    
}


const deleteProduct = (data) =>{
    setDeleteProductDialog(true)
    setsubscribersdeleteid(data)
    setname(data.User.name)
}

const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
           
            <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
         
        </React.Fragment>
    )
}

    const [viewdata, setViewdata] = useState(0);
    const viewOption = [
        { name: 'Due(Expiring in 7days)', value: 1 },
        { name: 'Expired Subscribers', value: 2 },
    ];


    
const [first1, setFirst1] = useState(0);
const [pages, settotalPages] = useState(0);

   

const rightToolbarTemplate = () =>{
    return (
        <React.Fragment>
            <Menu model={items} popup ref={exportmenu} id="popup_menu_right" style={{width: '170px'}} />
            <div className="card flex justify-content-center">
            <Dropdown value={viewdata} onChange={(e) => setViewdata(e.value)}  showClear options={viewOption} optionLabel="name" 
            placeholder="All Subscribers Data" className="w-full md:w-14rem" />
             </div>
            <Button label="Export" icon="pi pi-upload" className="p-button-info ml-3" aria-controls="popup_menu_right" aria-haspopup onClick={(event) => exportmenu.current.toggle(event)}  />
        </React.Fragment>
    )
}


const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={()=>editProduct(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={()=>deleteProduct(rowData)} />
        </React.Fragment>
    );
}


const phoneBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            {rowData.User.countrycode} {rowData.User.phoneno}
        </React.Fragment>
    );
}

// const [globalfilter, setGlobalFilter] = useState(null)

const header = (
    <div className="table-header">
        <h5 className="mx-0 my-1">Manage Subscribers</h5>
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e)=>setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
    </div>
);
const productDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Add Subscriber" icon="pi pi-check" className="p-button-text" 
        onClick={()=> addsubscriber()} />
      </div>
    </React.Fragment>
);
const updateDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideupdateDialog} />
        <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={()=>editsubscriber()} />
      </div>
    </React.Fragment>
);
const deleteProductDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>deletesubscriber()} />
    </React.Fragment>
);


const onCustomPage1 = (event) => {
    setFirst1(event.first);
    
}

const template1 = {
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Previous</span>
                <Ripple />
            </button>
        )
    },
    'NextPageLink': (options) => {
      
        
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Next</span>
                <Ripple />
            </button>
        )
    },
    'PageLinks': (options) => {
        // console.log(options)
        settotalPages(options.currentPage)
        // console.log('pages',pages)
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
  
};


const [updateid, setUpdateid] = useState()
const [updatestartdate, setupdateStartDate] = useState()
const [updateendvalue, setUpdateendvalue] = useState("")
const [updateclick, setUpdateclicked] = useState(false)
const [updateenddate, setUpdateenddate] = useState()
const [subscriberdeleteid, setsubscribersdeleteid] = useState('')
const [name, setname] = useState('')
const [mindatestate, setMindatestate] = useState("")
const [updatechangeendate, setUpdatechangeenddate] = useState("")


const editProduct = (data) => {
   
    setupdateDialog(true)
    setUpdateid(data.user_id)
    setupdateStartDate(data.start_date)
    setUpdateendvalue(data.period)
    setname(data.User.name)
    setUpdateenddate(data.end_date)
    setUpdatechangeenddate(data.end_date)
    setMindatestate(data.start_date)

}


    
useEffect(()=>{
    const d = updatestartdate ? new Date(updatestartdate) : new Date();
    const dateformat = new Date(d.setMonth(d.getMonth() + updateendvalue))
    const date = dateformat.getDate()
    let month = dateformat.getMonth() +1
    const year = dateformat.getFullYear()
    month = month <=9 ? "0"+month : month
   const updatedate = year+"-"+month+"-"+date
    setUpdateenddate(updatedate)


},[updateendvalue, updatestartdate ])

const updateendHandler = ((e)=>{

    const dateformat = new Date(e)
    const date = dateformat.getDate()
    let month = dateformat.getMonth() +1
    const year = dateformat.getFullYear()
    month = month <=9 ? "0"+month : month
   const updatedate = year+"-"+month+"-"+date
    setUpdateenddate(updatedate)
})

const editsubscriber = (()=>{

    let d = new Date();

    const body ={

        id: updateid,
        start_date: updatestartdate,
        end_date: updateenddate,
        period: updateendvalue
        }
       
        // console.log("update body ", body)
    !updatestartdate ?
      setUpdateclicked(true)
    :
    updatesubscribers(body).then((res)=>{
        // console.log(res)
        setupdateDialog(false)
        getallsubscribers()
        setUpdatechangeenddate(null)
        toast.current.show({ severity: 'info', summary: 'Update Subscriber', detail: 'Subscriber Updated Successfully',life: 3000 });
    }).catch((err)=>{
       
        setupdateDialog(false)

        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

    })

})
//   console.log('sdfsd',subscriberdeleteid.id)

const deletesubscriber = ()=>{
    const body = { 
        id: subscriberdeleteid.id
    }
   
    deletesubscribers(body)
    .then((res)=>{
 
        getallsubscribers()
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'info', summary: 'Delete Subscriber', detail: 'Subscriber Deleted Successfully',life: 3000 });
    }).catch((err)=>{
        // console.log(err)
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
        
    })


}


const [startdate, setStartDate] = useState(new Date())
const [enddate, setEndDate] = useState(null)
const [subscribers, setsubscribers] = useState()
const [subscriberscount, setsubscriberscount] = useState(0)


const [endstartdate, setEndstartdate] = useState()

useEffect(()=>{
    let date = new Date(enddate);

    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    mm = mm <=9 ? "0"+mm : mm
   const enddates = yyyy+"-"+mm+"-"+dd
   setEndstartdate(enddates)
},[enddate])

useEffect(()=>{
   
   const d = new Date(startdate)
   const dateformat = new Date(d.setMonth(d.getMonth() + enddatevalue))
   const date = dateformat.getDate()
   let month = dateformat.getMonth() +1
   const year = dateformat.getFullYear()
   month = month <=9 ? "0"+month : month
  const enddates = year+"-"+month+"-"+date
  setEndstartdate(enddates)
},[startdate])

const [enddatevalue, setenddatevalue] = useState("")

const enddateHandler = (period) =>{
  // console.log("period", period);
  setenddatevalue(period)
  let d = new Date();
  
  d.setMonth(d.getMonth() + period);

  setEndDate(d)
}

const getallsubscribers = (() => {

    const body ={
        pageno: pages,
        type: viewdata
    }
    getsubscribers (body) 
    .then((res) => {
      
        setsubscriberscount(res.data.data.count)
        setsubscribers(res.data.data.rows)
        
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        });
  
  })


  const subscribersearch = (() => {

    
    const body ={
        content: globalFilter,
        pageno: pages,
        type: viewdata
    }

    searchsubscriber (body) 
    .then((res) => {
        
        setsubscribers(res.data.data.rows)
        setsubscriberscount(res.data.data.count)
       
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            window.location.reload(true)
        } 
    
        })
        

  })

  useEffect(()=>{
    globalFilter ? subscribersearch() : getallsubscribers()
  },[pages, viewdata, globalFilter])



const [subscriberserrormsg, setSubscriberserrormsg] = useState("")
const [submitted, setSubmitted] = useState(false)
const addsubscriber = (() => {
    setSubmitted(true)
    const body = {
        user_id: addusersubscribers,
        start_date: startdate,
        end_date: enddate,
        period: enddatevalue
    }
    
    addsubscribers (body) 
    .then((res) => {
        // console.log('res ' , res)
        getallsubscribers()
        setProductDialog(false)
        toast.current.show({ severity: 'info', summary: 'Add Subscriber', detail: 'Subscriber Added Successfully',life: 3000 });
        setaddusersubcribers("")
        setStartDate("")
        setenddatevalue("")
       
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            window.location.reload(true)
        }
        if(err.response.status === 400 && err.response.data.error === "subscribtion already exists"){
            setSubscriberserrormsg("subscriber already exists")
            setTimeout(() => {
                setSubscriberserrormsg("")
            }, 2000);
            setProductDialog(true)
        }else{
            setaddusersubcribers("")
            setStartDate("")
            setenddatevalue("")
            
            toast.current.show({ severity: 'info', summary: 'Failure', detail: 'Something went wrong',life: 3000 });
        }
       
        // console.log(err)
        });
        setSubmitted(false)
    
   
  })



  const [users, setUsers] = useState('')


  const data = []

  users && users.map((datas)=>(
    data.push( {"id":[datas.id], "name":[ datas.name," - ",datas.countrycode," " ,datas.phoneno]} )
  ))


//   console.log("dartaaaa", data);

  const [addusersubscribers, setaddusersubcribers] = useState("")

// console.log('userssssidd',addusersubscribers)

  const  albumidHandler = (e) =>{
    setaddusersubcribers(e.value)   
    
}
  const getusersdata = useCallback(async() => {
    
    getusers () 
    .then((res) => {
        
        setUsers(res.data.data)
        
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            window.location.reload(true)
        }
        
        });
  
  })

  

  useEffect(()=>{
    getusersdata()
  },[])

  

  const [pricingdata, setpricingdata] = useState('')


  const label = []
  
  pricingdata&&pricingdata.map( data => (
    label.push({"label": data.period + ` months - Rs. ${data.price}`, "value": data.period})
 ));
  
    

  const getpricing = useCallback(async() => {
    getpricingdata () 
    .then((res) => {
        
        setpricingdata(res.data.data)
        
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        // console.log("prierr", err);
        });
  
  })

  useEffect(()=>{
    getpricing()
  },[])
  




  const [minDate2, setMindate2] = useState()

  useEffect(()=>{
    
    const dateformat = new Date(updatestartdate)
    
      const minDate2 = new Date()
    
      const month2 = dateformat && dateformat.getMonth()
      const year2= dateformat && dateformat.getFullYear()
      const date2 = dateformat && dateformat.getDate()
    
      minDate2.setMonth(month2)
      minDate2.setFullYear(year2)
      minDate2.setDate(date2+1)
      setMindate2(minDate2)
    },[updatestartdate])
   


    return (
      <>
      
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

              <DataTable ref={dt} lazy value={subscribers} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id" paginator
                  
                  paginatorTemplate={template1} first={first1} rows={10} totalRecords={subscriberscount}
                  onPage={onCustomPage1} 
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Subscribers"
                   header={header} tableStyle={{ maxWidth: '20rem' }} responsiveLayout="scroll">
                  

                  <Column field="User.name"  header="Name" alignHeader='center' className='text-center' style={{ minWidth: '12rem'}}></Column>
                  <Column body={phoneBodyTemplate} header="Phone No" alignHeader='center' className='text-center'  style={{ minWidth: '12rem' }}></Column>
                  <Column field='User.email' header="Email" alignHeader='center' className='text-center'  style={{ minWidth: '16rem' }}></Column>
                  <Column field='end_date' header="End Date" alignHeader='center' className='text-center'  style={{ minWidth: '13rem' }}></Column>
                 
        
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
    
          </div>

          <Dialog visible={productDialog} style={{ width: '370px' }} header="Add Subscribers" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <small className="p-error">{subscriberserrormsg && subscriberserrormsg}</small>
              <div className="field">
                <label >User Name</label>   
                <Dropdown value={addusersubscribers} filter options={data} optionLabel="name" optionValue="id" onChange={albumidHandler} placeholder="User Info" className='mt-2 w-12' />
                {submitted && !addusersubscribers && <small className="p-error">User is required</small>}

              </div>
              <div className="field">
                <label >Start Date</label>  
                <Calendar value={startdate} dateFormat="yy-mm-dd" onChange={(e) => setStartDate(e.value)} showIcon />
                {submitted && !startdate && <small className="p-error">Start date is required.</small>}
              </div>
              <div className="field">
                <label >Subscription period</label>  
                
                <Dropdown value={enddatevalue} options={label} optionLabel="label" optionValue="value" onChange={(e) => enddateHandler(e.value)} placeholder="Select value..." className='mt-2 w-12' />
                {submitted && !enddatevalue &&<small className="p-error">Subscription period is required</small>}
                
              </div>
              {enddatevalue && endstartdate && <small className='mt-6 mr-5'>End Date: {endstartdate}</small>}

          </Dialog>

          <Dialog visible={updateDialog} style={{ width: '440px' }} header="Update Subscribers" modal className="p-fluid" footer={updateDialogFooter} onHide={hideupdateDialog}>
              <div className="field">
                <label >Subscriber Name</label>   
               
              <h3 className="m-1">{name}</h3>
              </div>
              <div className="field">
                <label >Start Date</label>  
                
                <Calendar value={new Date(updatestartdate)} placeholder={updatestartdate}  onChange={(e) => setupdateStartDate(e.value)}  dateFormat="yy-mm-dd" showIcon />
                {!updatestartdate && updateclick && <small className="p-error">Enter Start Date</small>}
              </div>
              <div className="field">
                <label >Subscription period</label>  
                <Dropdown value={updateendvalue}  options={label} optionLabel="label" optionValue="value" onChange={(e) => setUpdateendvalue(e.value)} placeholder="Select value..." className='mt-2 w-12' />
                {!updateendvalue && updateclick &&<small className="p-error">Enter Subscription Period</small>} 
              </div>

              <div className="field mt-4">
                <label >End Date</label>  
               
                <Calendar value={new Date(updateenddate)} minDate={minDate2} onChange={(e) => updateendHandler(e.value)} className="p-invalid" dateFormat="yy-mm-dd" showIcon />
                {!updateendvalue && updateclick &&<small className="p-error">Enter Subscription Period</small>} 
              </div>

          </Dialog>



          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {subscriberdeleteid && <span>Are you sure you want to delete <b>{name}</b>?</span>}
              </div>
          </Dialog>

         
      </div>
      </div>
      </div>
      </div>
      </>
      );
}

