
import axios from 'axios';


const accessToken = localStorage.getItem('token')

const apiURL = process.env.REACT_APP_MODE === "prod" ? process.env.REACT_APP_PROD_API  : process.env.REACT_APP_DEV_API


export const login = (email, password) =>{

  return new Promise((resolve, reject) => {

    try{
        const res=  axios.post(`${apiURL}/admin/login`, {}, 
          { auth:{
            username: email,
            password: password
          }
          })
        resolve(res)
    }catch (err){ 
      reject(err)
    
      }
});

}

// dashboard data

export const dashboardapi =(()=>{
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: 'get',
        url: `${apiURL}/admin/subscriber/dashboard`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
})


// category data

export const catdatafetch = () => {

    const authAxios = axios.create({
        baseURL: apiURL,
        headers:{
          Authorization: `Bearer ${accessToken}`
        }
      })

    return new Promise((resolve, reject) => {

        try{
            const res= authAxios.get(`/admin/category`);
            resolve(res)
        }catch (err){ 
          reject(err)
        
          }
    });

}


export const savecatProduct = (body) =>{

    return new Promise((resolve, reject) => {

        try{
            const res = axios({
                method: 'post',
                url: `${apiURL}/admin/category`,
                
                headers:{
                    Authorization: `Bearer ${accessToken}`
                  },
                data: body
            })
            resolve(res)
            
        
        }catch (err){ 
          reject(err)
        
          }
    });
}

// category delete api


export const delcategory = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'delete',
            url: `${apiURL}/admin/category/delete`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// category update api

export const updatecategory = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/category/update`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
              
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// Album data fetch api 

export const albumdata = (pages) =>{

  const authAxios = axios.create({
      baseURL: apiURL,
      headers:{
        Authorization: `Bearer ${accessToken}`
      }
    })

  return new Promise((resolve, reject) => {

      try{
          const res= authAxios.get(`/admin/albums/${pages}`);
          resolve(res)
      }catch (err){ 
        reject(err)
        if(err.response.status === 401){
          localStorage.removeItem('token')
          window.location.reload(true)
      }
      
        }
  });

}



// Get all Album data fetch api (dropdowns)

export const totalalbumdata = () =>{

  const authAxios = axios.create({
      baseURL: apiURL,
      headers:{
        Authorization: `Bearer ${accessToken}`
      }
    })

  return new Promise((resolve, reject) => {

      try{
          const res= authAxios.get(`/admin/albums/list`);
          resolve(res)
      }catch (err){ 
        reject(err)
        if(err.response.status === 401){
          localStorage.removeItem('token')
          window.location.reload(true)
      }
      
        }
  });

}

// album add api


export const addalbum = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/category/update`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
              
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// album save api


export const savealbum = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/album`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body,
            
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// album image upload api(signedURl)


export const imagesignedurl = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/signed/url`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },   
            

            data: body,
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });

}



// album delete api

export const delalbum = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'delete',
            url: `${apiURL}/admin/album/delete`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// album update api

export const updatealbum= (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/album/update`,
            headers:{
                Authorization: `Bearer ${accessToken}`
                },
                
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// episode audio upload api

export const audioUpload = (body) =>{

  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/episode/audio/signed/url`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },   
            

            data: body,
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });

}



// episode data fetch api

export const episodedata = (showalbumId, pages) =>{

  const authAxios = axios.create({
      baseURL: apiURL,
      headers:{
        Authorization: `Bearer ${accessToken}`
      }
    })

  return new Promise((resolve, reject) => {

      try{
          const res= authAxios.get(`/admin/episode/${showalbumId}/${pages}`);
          resolve(res)
      }catch (err){ 
        reject(err)
        if(err.response.status === 401){
          localStorage.removeItem('token')
          window.location.reload(true)
      }
      
        }
  });

}


// episode save api


export const saveepisode = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/episode`,
            headers:{
                Authorization: `Bearer ${accessToken}`
            },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// episode update api

export const updateepisode= (body1) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/episode/update`,
            headers:{
                Authorization: `Bearer ${accessToken}`
                },
                
            data: body1
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// episode delete api

export const delepisode = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'delete',
            url: `${apiURL}/admin/episode/delete`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}


// episode audio file delete api

export const deleteaudiofile = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'delete',
            url: `${apiURL}/admin/audio/file`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
      }catch (err){ 
        reject(err)
      }
  });
}


//user api call


export const getuser = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/user/list`,
            headers:{
                Authorization: `Bearer ${accessToken}`
                },
                
            data: body
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// users api call for add subscribers(all users data)



export const getusers = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'get',
            url: `${apiURL}/admin/user/list`,
            headers:{
                Authorization: `Bearer ${accessToken}`
                },
                
            data: body
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// users search api 

export const searchuser = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/user/search`,
            headers:{
                Authorization: `Bearer ${accessToken}`
                },
                
            data: body
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// Add User to Restricted Album

export const addrestrictedusers= (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/restricted/add`,
            headers:{
                Authorization: `Bearer ${accessToken}`
                },
                
            data: body
           
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// restricted user data fetch

export const restricteduserlist = (id) =>{

  const authAxios = axios.create({
      baseURL: apiURL,
      headers:{
        Authorization: `Bearer ${accessToken}`
      }
    })

  return new Promise((resolve, reject) => {

      try{
          const res= authAxios.get(`/admin/album/restricted/users/${id}`);
          resolve(res)
      }catch (err){ 
        reject(err)
        
      
        }
  });

}


// user delete api(Delete User from Restricted Album  )

export const deluser = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'delete',
            url: `${apiURL}/admin/restricted/delete`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// Get Subscribers


export const getsubscribers = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'post',
            url: `${apiURL}/admin/subscribers`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// Add User

export const addUser = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'post',
            url: `${apiURL}/admin/user/add`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// Delete User


export const deleteuser = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'post',
            url: `${apiURL}/admin/user/delete`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// Add Subscriber

export const addsubscribers = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'post',
            url: `${apiURL}/admin/subscriber`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// Update Subscriber


export const updatesubscribers = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'post',
            url: `${apiURL}/admin/subscriber/update`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}


// Delete Subcriber waiting list


export const deletesubscriberswaiting = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'delete',
            url: `${apiURL}/admin/subscriber/waiting`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}

// Delete Subcriber


export const deletesubscribers = (body) =>{
    
  return new Promise((resolve, reject) => {

      try{
          const res =  axios({
            method: 'delete',
            url: `${apiURL}/admin/subscriber`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}



// slider image upload api(get signed url)

export const sliderimage = (body) =>{
    return new Promise((resolve, reject)=>{
      try{
        const res = axios({
          method: 'post',
          url: `${apiURL}/admin/signed/url`,
          headers:{
            Authorization: `Bearer ${accessToken}`
          },
          data: body
        })
        resolve(res)
      }
      catch(err){
        reject(err)
      }
    })
  }

// slider add image api

export const addslide = (body) =>{
  console.log(' addd image api', body)
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: 'post',
        url: `${apiURL}/admin/slide/add`,
        headers:{
          Authorization: `Bearer ${accessToken}`
        },
        data: body
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
}



// get slider images

export const getsliderimages =(()=>{
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: 'get',
        url: `${apiURL}/admin/slides/list`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
})


// delete slide image

 export const sliderimagedelete = ((body)=>{
  
    return new Promise ((resolve, reject)=>{
      try{
        const res = axios({
          method: 'post',
          url: `${apiURL}/admin/slide/delete`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: body

        })
        resolve(res)
      }
      catch(err){
        reject(err)
      }
    })
  })



  // change password api


export const changepassword = (body) =>{
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: 'post',
        url: `${apiURL}/admin/change/password`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: body
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
}

// subscriber search api


export const searchsubscriber = (body) =>{
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: 'post',
        url: `${apiURL}/admin/subscriber/search`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: body
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
}

// subscribers waiting list

export const subscriberswaitinglist = (pageno) =>{
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: "get",
        url: `${apiURL}/admin/subscribers/waiting/${pageno}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
}

// subscribers waiting list search api

export const subscriberwaitinglistsearch = (body) =>{

  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/subscribers/waiting/search`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
              
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}


// pricing api


export const getpricingdata = () =>{
  return new Promise((resolve, reject)=>{
    try{
      const res = axios({
        method: "get",
        url: `${apiURL}/admin/pricing`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      })
      resolve(res)
    }
    catch(err){
      reject(err)
    }
  })
}


// add pricing


export const addpricing = (body) =>{

  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/pricing`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
              
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}


// delete pricing

export const deletepricing = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'delete',
            url: `${apiURL}/admin/pricing`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
            data: body
        })
          resolve(res)
          
      
      }catch (err){ 
        reject(err)
      
        }
  });
}


// edit pricing 

export const updatepricing = (body) =>{

    
  return new Promise((resolve, reject) => {

      try{
          const res = axios({
            method: 'post',
            url: `${apiURL}/admin/pricing/edit`,
            headers:{
                Authorization: `Bearer ${accessToken}`
              },
              
            data: body
        })
          resolve(res)
      
      }catch (err){ 
        reject(err)
      
        }
  });
}


// export data api

export const exportdata = (data) => {
  return new Promise((resolve, reject) => {

    try{
        const res = axios({
          method: 'get',
          url: `${apiURL}/admin/subscribers/export/${data}`,
          headers:{
              Authorization: `Bearer ${accessToken}`
            }
      })
        resolve(res)
    }catch (err){ 
      reject(err)
    
      }
});
}