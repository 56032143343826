import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate  } from 'react-router-dom';
import { addUser, deleteuser, getuser, searchuser } from '../Apicall';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'


export default function Adduser() {

const navigate = useNavigate()

const [productDialog,   setProductDialog] = useState(false);
const [deleteProductDialog, setDeleteProductDialog] = useState(false);

const [submitted, setSubmitted] = useState(false);
const [globalFilter, setGlobalFilter] = useState(null);


const toast = useRef(null);
const dt = useRef(null);

const openNew = () => {
   
    setSubmitted(false);
    setProductDialog(true);
}

const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setUserName("")
    setPassword("")
    setNationalNumber("")
}

const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
}



const [userdeleteid, setuserdeleteid] = useState('')
const [name, setname] = useState('')




const deleteProduct = (data) =>{
    setDeleteProductDialog(true)
    setuserdeleteid(data)
    // console.log("DELTE", data)
    setname((data.name))
}

const   leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        </React.Fragment>
    )
}


const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
           
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={()=>deleteProduct(rowData)} />
        </React.Fragment>
    );
}

const phoneBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            {rowData.countrycode && rowData.countrycode} {rowData.phoneno && rowData.phoneno}
        </React.Fragment>
    );
}

const header = (
    <div className="table-header">
        <h5 className="mx-0 my-1">Manage Users</h5>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span> 
    </div>
);

const [first1, setFirst1] = useState(0);
const [pages, settotalPages] = useState(0);

const onCustomPage1 = (event) => {
    setFirst1(event.first);
    
}

const template1 = {
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Previous</span>
                
            </button>
        )
    },
    'NextPageLink': (options) => {
      
        
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Next</span>
                
            </button>
        )
    },
    'PageLinks': (options) => {
        // console.log(options)
        settotalPages(options.currentPage)
        // console.log('pages',pages)
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
               
            </button>
        )
    },
  
};


const [usererror, setUserError] = useState("")

const addusers = () => {
    // console.log("fucntion called");
    setSubmitted(true)

    const body ={
        name: username,
        password: password,
        phoneno : nationalNumber,
        countrycode:  `+${countryCallingCode}`
    }

    if(!username || !password || !nationalNumber ){
        setProductDialog(true)

    }else{

    addUser (body) 
    .then((res) => {
        // console.log('res ' , res)
        if(res.data.message==="இந்த தொடர்பு எண் ஏற்கனவே பதிவு செய்யப்பட்டுள்ளது. மேலும் உதவிக்கு semmaisevi@gmail.com மின்னஞ்சல் முகவரியை தொடர்பு கொள்ளவும்."){
            // console.log("User Already Exists");
            setUserError("User Already Exists")
            setTimeout(() => {
                setUserError("")
            }, 3000);
            setProductDialog(true)
        }else{
            getusers()
            setProductDialog(false)
            toast.current.show({ severity: 'info', summary: 'Add User', detail: 'User Added Successfully',life: 3000 });
            setTimeout(() => {
                setUserName("")
                setPassword("")
                setNationalNumber("")
            }, 700);
           
        }
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
            
        }
        // console.log(err)
        setProductDialog(false)
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
        setUserName("")
        setPassword("")
        setNationalNumber("")
    });
   
}}




const productDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Add User" icon="pi pi-check" className="p-button-text" 
        onClick={addusers} />
      </div>
    </React.Fragment>
);

const deleteProductDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>userdelete()} />
    </React.Fragment>
);


const userdelete = ()=>{
   
    const body = { 
        user_id: userdeleteid.id
    }


    deleteuser(body)
    .then((res)=>{
  
        getusers()
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'info', summary: 'Delete Subscriber', detail: 'Subscriber Deleted Successfully',life: 3000 });
    }).catch((err)=>{
      
        if(err.response.data.message === "இந்த உறுப்பினருக்கு சந்தா தகவல் உள்ளது. ஆகவே உறுப்பினர் தகவலை நீக்க முடியாது. " ){
            setDeleteProductDialog(false);
            
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'இந்த உறுப்பினருக்கு சந்தா தகவல் உள்ளது. ஆகவே உறுப்பினர் தகவலை நீக்க முடியாது.', life: 10000 });
        }else{
            setDeleteProductDialog(false);
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
        }
        

    })

}



const [nationalNumber, setNationalNumber] = useState('')
const [countryCallingCode, setCountryCallingCode] = useState('')
const [invalidmsg, setInvalidNumber] = useState('')

// console.log("cccccc", countryCallingCode);

const phonenumberInput = (event) =>{

    const stringValue = event && (event).toString()
    

    const valid = stringValue && isValidPhoneNumber(stringValue)
  
    const phoneNumber  = stringValue && parsePhoneNumber((stringValue).toString())


    if (phoneNumber) {
        setCountryCallingCode(phoneNumber.countryCallingCode )
        setNationalNumber(phoneNumber.nationalNumber)
    }


    const phonenumbervalidator = (()=>{

        if(valid){
            setInvalidNumber(" ")
        }else{
            setInvalidNumber("Invalid Phone Number")
        } 
    
    })
    phonenumbervalidator()
}


const [username, setUserName] = useState("")
const [password, setPassword] = useState("")



const [users, setUsers] = useState('')
const [userscount, setUserscount] = useState('')

  const getusers = useCallback(async() => {
    const body = {
        pageno: pages
    }
    getuser (body) 
    .then((res) => {
        
        setUsers(res.data.data.rows)
        setUserscount(res.data.data.count);
        
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        console.log("users list err", err);
        });
  
  })


  const searchuserslist = (() => {
    
    const body ={
        pageno: pages,
        content: globalFilter
    }
 
        searchuser (body) 
        .then((res) => {
   
            setUsers(res.data.data.rows)
            setUserscount(res.data.data.count);
            
        })
        .catch((err) => {
            if(err.response.status === 401){
                localStorage.removeItem('token')
                navigate("/");
            }
            })
        
  
  })


  useEffect(()=>{
    globalFilter ? searchuserslist() :  getusers()
  },[pages,globalFilter])


    return (
      <>
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
 

             <DataTable ref={dt} lazy value={users} 
                  dataKey="id" paginator
                  
                  paginatorTemplate={template1} first={first1} rows={10} totalRecords={userscount}
                  onPage={onCustomPage1} 
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Subscribers"
                   header={header} tableStyle={{ maxWidth: '20rem' }} responsiveLayout="scroll">
                  

                  <Column field="name"  header="Name" sortable alignHeader='center' className='text-center' style={{ minWidth: '12rem'}}></Column>
                  <Column body={phoneBodyTemplate} header="Phone No" alignHeader='center' className='text-center'  style={{ minWidth: '16rem' }}></Column>
                  <Column field="email" header="Email" alignHeader='center' className='text-center'  style={{ minWidth: '16rem' }}></Column>

                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
    
          </div>

          <Dialog visible={productDialog} style={{ width: '370px' }} header="Add new user" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
            <small className='p-error'>{usererror && usererror}</small>
            <div className="field">
                <label htmlFor="name">User Name</label>
                <InputText id="name" value={username} keyfilter="alpha" onChange={(e) => setUserName(e.target.value)} required={true} autoFocus className={classNames({ 'p-invalid': submitted && !username })} />
               
                {submitted && !username && <small className="p-error">Name is required.</small>}
            </div>
            <div className="field">
                <label htmlFor="name">Phone Number</label>
                <PhoneInput
                    placeholder="Enter phone number"
                   className='phone'
                    defaultCountry="IN"
                    // onFocus={phonenumbervalidator}
                    onChange={phonenumberInput}/>


                {submitted && !nationalNumber && <small className="p-error">PhoneNumber is required.</small> }

                {invalidmsg &&  <p> {invalidmsg}</p>}
            </div>
            <div className="field">
                <label htmlFor="name">Password</label>
                <InputText id="name" value={password} onChange={(e) => setPassword(e.target.value)}  className={classNames({ 'p-invalid': submitted && !password })} />
                {submitted && !password && <small className="p-error">Passowrd is required.</small>}
            </div>  
              
          </Dialog>

          

          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {userdeleteid && <span>Are you sure you want to delete <b>{name}</b>?</span>}
              </div>
          </Dialog>

         
      </div>
      </div>
      </div>
      </div>
      </>
      );
}

