import React, { useState  } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link, useNavigate  } from 'react-router-dom';
import { changepassword } from '../Apicall';

export default function Setnewpassword(){

    const navigate =  useNavigate()
   

const [password, setPassword] = useState('')
const [password2, setPassword2] = useState('')


const [ successmsg ,setSuccessMsg] = useState(false)
const [ errormsg ,setErrorMsg] = useState(false)

const changepwd = () =>{

    if(password === password2){
       
        const body ={
            password: password,
            token: localStorage.getItem('token')
        }
    
        changepassword(body)
        .then((res)=>{
            // console.log("loginres", res);
            setSuccessMsg(true)
            setPassword("")
            setPassword2("")
            
            setInterval(()=>{
                setSuccessMsg(false)
                localStorage.removeItem('token')
                navigate("/login")
            }, 1000)
        })
        .catch((err)=>{
            // console.log("loginerr", err);
            setErrorMsg("Please Try Again")
            setInterval(()=>{
                setErrorMsg(false)
            }, 2000)
        })
    
    }else{
        
        setErrorMsg("Password Must be Same")
        setInterval(()=>{
            setErrorMsg(false)
        }, 2000)

    }

    
}
   



return(

    <div>
        <div className="flex align-items-center justify-content-center" style={{height:'100vh'}}>
            <div className="surface-card p-4 shadow-2 border-round lg:w-4 ">
                <div className="text-center mb-5">
                    {/* <img src="pi pi-lock-open" icon="pi pi-lock-open" alt="hyper" height={50} className="mb-3" /> */}
                    <i className="pi pi-key text-600 font-medium line-height-3 m-3 py-1 px-2" style={{fontSize: '1.2em', color:'red', backgroundColor: 'rgb(130,168,205)', borderRadius: '50%' }}></i>
                    <div className="text-bold text-3xl font-medium mb-3  " >Set New Password</div>
                    <span className="text-600 font-medium line-height-3">Your new password must be different to previously used password.</span>
                    {/* <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */}
                </div>

                <div>

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                    <InputText id="password" value={password} onChange={e => setPassword(e.target.value)}  type="password" placeholder="Password" className="w-full mb-3" required/>

                    <label htmlFor="confirmpassword" className="block text-900 font-medium mb-2">Confirm Password</label>
                    <InputText id="confirmpassword" value={password2} onChange={e => setPassword2(e.target.value)}  type="password" placeholder="Confirm Password" className="w-full mb-3" required/>
                    {successmsg && <p style={{textAlign: 'center'}}>Password Changed Successfully</p>}
                    {errormsg && <p style={{color: 'red'}}>{errormsg}</p>}
                    <Button label="Reset Password" icon="pi pi-lock-open" onClick={changepwd}  className="w-full" />
                    <div className="text-center mt-4">
                    <Link className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
                        <i className="pi pi-arrow-left text-600 font-medium line-height-3" ></i>
                        <Link to="/login" replace>  <span className="text-600 font-medium line-height-3 ml-2" >Back to log in</span></Link>
                    </Link>
                    
                    </div>

                </div>
            </div>
        </div>
    </div>

)}