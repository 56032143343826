import React from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";

export default function Playlist() {
    return (
        <>
        <div style={{display:'flex'}}>
                <div>
                    <Sidebar />
                </div>
                <div style={{width:'100%'}}>
                    <Navbar/>   
                    <p>Playlist page</p>
                </div>
                
            </div>
            
        </>
      );
}

