import "./App.css";
import React from "react";
import Home from "./components/Home";
import Category from "./components/category/Category";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Albums from "./components/albums/Albums";
import Episodes from "./components/episodes/Episodes";
import Playlist from "./components/playlist/Playlist";
import Login from "./components/login/Login";
import Forgetpassword from "./components/forgetpassword/Forgetpassword";
import Setnewpassword from "./components/setpassword/Setnewpassword";
import Addsubscribers from "./components/addsubcribers/Addsubscribers";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Adduser from "./components/adduser/AddUser";
import Sliderimage from "./components/sliderimage/Sliderimage";
import Subcriberswaitinglist from "./components/subscriberswaitinglist/Subscriberswaitinglist";
import Pricing from "./components/pricing/pricing";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <ProtectedRoutes>
                <Home />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/category"
            element={
              <ProtectedRoutes>
                <Category />
              </ProtectedRoutes>
            }
          />
          <Route path="/albums" element={<Albums />} />

          <Route
            path="/episodes"
            element={
              <ProtectedRoutes>
                <Episodes />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/playlist"
            element={
              <ProtectedRoutes>
                <Playlist />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sliderimage"
            element={
              <ProtectedRoutes>
                <Sliderimage />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/forgetpassword"
            element={
              <ProtectedRoutes>
                <Forgetpassword />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/setnewpassword"
            element={
              <ProtectedRoutes>
                <Setnewpassword />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/addsubscribers"
            element={
              <ProtectedRoutes>
                <Addsubscribers />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/addnewuser"
            element={
              <ProtectedRoutes>
                <Adduser />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/subscriberswaitinglist"
            element={
              <ProtectedRoutes>
                <Subcriberswaitinglist/>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/pricing"
            element={
              <ProtectedRoutes>
                <Pricing/>
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
