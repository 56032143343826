import React from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { Link, useNavigate  } from 'react-router-dom';



export default function Sidebar(){
  
   const navigate = useNavigate()
   const sidebarItems = [
   
       {
           label: 'செம்மை செவி' ,
           className: 'home-txt',
           command: () => {
            // navigate("/")
            window.location = "/";
          }
   
       },
       {   
           
           separator:true
        },
       {
   
          label: 'Dashboard',
          icon:'pi pi-th-large',
          className: 'home-padding',
          command: () => {
            // navigate("/")
            window.location = "/";
          }
          
       },
       {
          label: 'Category(தொகை)',
          icon:'pi pi-folder-open',
          command: () => {
            // navigate("/category")
            window.location = "/category";
          }

          
       },
       {
          label:'Albums(கோவை)',
          icon:'pi pi-book',
          command: () => {
            // navigate("/albums")
            window.location = "/albums";

          }
          
       },
       {
          label:'Episodes(கூறு)',
          icon:'pi pi-video',
          command: () => {
            // navigate("/episodes")
            window.location = "/episodes";
         }
          
       },
       {
         label:'Upload Slider Image',
         icon:'pi pi-upload',
         command: () => {
            // navigate("/sliderimage")
            window.location = "/sliderimage";

         }
         
      },
       {
          separator:true
       },
       {
         label: `Subscribers Requests`,
         icon: 'pi pi-comments',
         command:()=>{
            //  navigate("/subscriberswaitinglist") 
         window.location = "/subscriberswaitinglist";
      }
      },
       {
         label: 'Users',
         icon: 'pi pi-user-plus',
         command:()=>{
            
            // navigate("/addnewuser")
            window.location = "/addnewuser";
         
         }
      },
      {
         label: 'Subscribers',
         icon: 'pi pi-user-plus',
         command:()=>{ 
            // navigate("/addsubscribers")
         window.location = "/addsubscribers";
      }
      },
      {
         separator:true
      },
      {
         label: 'Pricing',
         icon: 'pi pi-cart-plus',
         command:()=>{ 
            // navigate("/pricing")
         window.location = "/pricing";
      }
      },
    
       
   ];
    return(
        <div className='sidebar' style={{flex:1.1, position:'fixed'}}>
        <TieredMenu model={sidebarItems} style={{height:'100vh',paddingTop:'8px',width:'100%'}} />
        </div>
    )   
    
}
