import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate  } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import { addpricing, getpricingdata, deletepricing, updatepricing } from '../Apicall';

export default function Pricing() {

const navigate = useNavigate()

const [productDialog,   setProductDialog] = useState(false);
const [updateDialog,   setupdateDialog] = useState(false);
const [deleteProductDialog, setDeleteProductDialog] = useState(false);


const [submitted, setSubmitted] = useState(false);
const [updated, setUpdated] = useState(false);

const toast = useRef(null);
const dt = useRef(null);

const openNew = () => {
   
    setSubmitted(false);
    setProductDialog(true);
}

const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setProductDialog(false)
}

const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
}

const hideupdateDialog = () => {
    // setUpdated(false);
    setupdateDialog(false);
}



const [pricedeleteid, setpricedelete] = useState('')


const deleteProduct = (data) =>{
    setDeleteProductDialog(true)
    setpricedelete(data)
  
}


const editProduct = (data) => {
    setupdateDialog(true)
    setUpdateId(data.id)
    setUpdateperiod(data.period) 
    setUpdateprice(data.price) 
}

const   leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <Button label="Add" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        </React.Fragment>
    )
}


const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
           <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={()=>editProduct(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={()=>deleteProduct(rowData)} />
        </React.Fragment>
    );
}

const header = (
    <div className="table-header">
        <h5 className="mx-0 my-1">Manage Pricing</h5>
    </div>
);


const [price, setPrice] = useState("")
const [period, setPeriod] = useState("")
const [errormsg, setErrormessage] = useState("")

const addpricinglist = () => {
   
    setSubmitted(true)

    const body ={
        price: price,
        period: period
    }

    if(!price || !period ){
        setProductDialog(true)

    }else{

    addpricing (body) 
    .then((res) => {
        // console.log('res ' , res)
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Price Added Suuccessfuly', life: 3000 });
        setProductDialog(false)
        setTimeout(() => {
            setPrice("")
            setPeriod("")
        }, 500);
        getpricing()
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
            
        }
        if(err.response.data.error === "error adding"){
            setErrormessage("Plan Already Exist")
            setProductDialog(true)
            setTimeout(() => {
                setErrormessage("")
            }, 2000);
        }else{
            
            setProductDialog(false)
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
            setPrice("")
            setPeriod("")
        }
        
    });
   
}}



const productDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Add Pricing" icon="pi pi-check" className="p-button-text" 
        onClick={addpricinglist} /> 
      </div>
    </React.Fragment>
);

const deleteProductDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>pricedelete()} />
    </React.Fragment>
);

const updateDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideupdateDialog} />
        <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={()=>editpricing()} />
      </div>
    </React.Fragment>
);


const pricedelete = ()=>{
    setUpdated(true)
    const body = { 
        id: pricedeleteid.id
    }
    // console.log('body', body)
    deletepricing(body)
    .then((res)=>{
        // console.log(res)
        getpricing()
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'User Deleted Successfully',life: 3000 });
        setUpdated(false)
        
        
    }).catch((err)=>{
        // console.log(err)
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
    })

}

const [updateid, setUpdateId] = useState("")
const [updateprice, setUpdateprice] = useState('')
const [updateperiod, setUpdateperiod] = useState('')


const editpricing = (()=>{
    const body ={

        id: updateid,
        price: updateprice,
        period: updateperiod
        }
        // console.log('updatedata', body)
        
    
    updatepricing(body).then((res)=>{
        // console.log(res)
        setupdateDialog(false)
        getpricing()
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'User Updated Successfully',life: 3000 });
        
    }).catch((err)=>{
        // console.log(err)
        setupdateDialog(false)

        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

    })

})


const [pricingdata, setpricingdata] = useState('')


  const getpricing = useCallback(async() => {
    getpricingdata () 
    .then((res) => {
        // console.log("pricingres", res);
        setpricingdata(res.data.data)
        
    })
    .catch((err) => {
        if(err.response.status === 401){
            localStorage.removeItem('token')
            navigate("/");
        }
        // console.log("prierr", err);
        });
  
  })

  useEffect(()=>{
    getpricing()
  },[])
  
  

    return (
      <>
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

              <DataTable ref={dt} value={pricingdata} 
                  dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                  responsiveLayout="scroll" header={header} >
                  

                  <Column field="period"  header="Period"  alignHeader='center' className='text-center' style={{ minWidth: '12rem'}}></Column>
                  <Column field="price" header="Pricing" alignHeader='center' className='text-center'  style={{ minWidth: '16rem' }}></Column>
                 
        
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
    
          </div>

          <Dialog visible={productDialog} style={{ width: '370px' }} header="Add Pricing" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
          
            <div className="field">
                <label htmlFor="name">Period (Must be in number(months) )</label>
                
                <InputNumber  id="name" placeholder='For Example (2, 3 ,6) etc' value={period} onValueChange={(e) => setPeriod(e.target.value)} autoFocus useGrouping={false} className={classNames({ 'p-invalid': submitted && !period })} />
                {submitted && !period && <small className="p-error">Period is required.</small>}
            </div>  
            <div className="field">
                <label htmlFor="name">Pricing (Must be in number)</label>
                
                <InputNumber  id="name" placeholder='For Example (100, 500, 1000) etc' value={price} onValueChange={(e) => setPrice(e.target.value)}  required={true} useGrouping={false} className={classNames({ 'p-invalid': submitted && !price })} />
                {submitted && !price && <small className="p-error">Price is required.</small>}
            </div>
        
            <small className='p-error'>{errormsg}</small>
              
          </Dialog>


          <Dialog visible={updateDialog} style={{ width: '370px' }} header="Update Pricing" modal className="p-fluid" footer={updateDialogFooter} onHide={hideDialog}>
           
            <div className="field">
                <label htmlFor="name">Period</label>
                <InputNumber  id="name"  value={updateperiod} onValueChange={(e) => setUpdateperiod(e.target.value)} autoFocus required={true} useGrouping={false} className={classNames({ 'p-invalid': submitted && !period })} />
                {updated && !updateperiod && <small className="p-error">Period is required.</small>}
            </div>  
            <div className="field">
                <label htmlFor="name">Pricing</label>
                <InputNumber  id="name"  value={updateprice} onValueChange={(e) => setUpdateprice(e.target.value)}  required={true} useGrouping={false} className={classNames({ 'p-invalid': submitted && !price })} />
                {updated && !updateprice && <small className="p-error">Price is required.</small>}
            </div>

          </Dialog>

          

          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {pricedeleteid && <span>Are you sure you want to delete?</span>}
              </div>
          </Dialog>

         
      </div>
      </div>
      </div>
      </div>
      </>
      );
}

