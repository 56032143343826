import React, { useState, useEffect, useRef  } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate  } from 'react-router-dom';
import { catdatafetch, delcategory, updatecategory } from '../Apicall';
import { savecatProduct } from '../Apicall';




export default function Category() {

    

    const navigate = useNavigate()
    useEffect(() =>{
        if(!localStorage.getItem('token')){
            navigate('/login')
        }
    },[])

  let emptyProduct = {
    id: null,
    name: '',
    Profile_pic: null,
    description: '',
    restricted: '',
    category_id: ''
};


const [productDialog,   setProductDialog] = useState(false);
const [updateDialog,   setupdateDialog] = useState(false);
const [deleteProductDialog, setDeleteProductDialog] = useState(false);
const [product, setProduct] = useState(emptyProduct);
const [selectedProducts, setSelectedProducts] = useState(null);
const [submitted, setSubmitted] = useState(false);
const [updated, setUpdated] = useState(false);
const [globalFilter, setGlobalFilter] = useState(null);
const toast = useRef(null);
const dt = useRef(null);




const openNew = () => {
    // setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
}

const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
}
const hideupdateDialog = () => {
    setUpdated(false);
    setupdateDialog(false);
}

const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
}


const [name, setName] = useState('');
const [shortname, setShortName] = useState('');
// const [icon, setIcon] = useState('');
const [counterr, setCountErr] = useState(false);



const saveProduct = () => {
       
    setSubmitted(true);

    if(!name || !shortname){
        return
    }else{
        var body = {
                name: name,
                shortName: shortname
                // icon:  icon,
            }  
    
                savecatProduct(body) 
                .then((res)=> {
                    // console.log(res)
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: ' Category Created sucessfully', life: 3000 });
                    datafetch()
                    setProductDialog(false); 
                    // navigate('/category')
                })
                .catch( (error) =>{
                    // console.log(error)
                    setProductDialog(false); 
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: ' Something Went Wrong', life: 3000 });
            
                });      
    }

   
   
}

const [updatename, setupdateName] = useState();
const [updateshortname, setshortupdateName] = useState();
// const [updateicon, setupdateIcon] = useState();
const[updateid,setupdateId] = useState();


const editProduct = (id) => {
    
    

    setupdateDialog(true);
    setupdateName(id.name)
    setshortupdateName(id.shortName)
    // setupdateIcon(id.icon)
    setupdateId(id)
}

const updateProduct = () =>{
    setUpdated(true)
    
    var body = {
        name: updatename,
        shortName: updateshortname,
        // icon:  updateicon,
        id: updateid.id
 
    }
    if(!updatename || !updateshortname ){
        setupdateDialog(true)
       
    }else{
        updatecategory(body)
        .then(function (response) {
        // console.log(response)
        datafetch()
        toast.current.show({ severity: 'info', summary: 'Category Updated', detail: 'Update Sucessfully',life: 3000 });
        setupdateDialog(false);
    })
    .catch(function (error) {
        // console.log(error);
        setupdateDialog(false);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong',life: 3000 });
        
    });      
    }
    
    
} 

const [deleteid, setDeleteId] = useState()

const confirmDeleteProduct = (product) => {
    setProduct(product);    
    setDeleteId(product)
    setDeleteProductDialog(true);
}

const deleteProduct = () => {

  
        var body = {  
            id: deleteid.id
        }

    
        delcategory(body)
        .then(function (response) {
    
            // console.log(response);
            
            datafetch()
            toast.current.show({ severity: 'info', summary: 'Category Deleted', detail: 'Delete Sucessfully',life: 3000 });
        })
        .catch(function (error) {
            // console.log(error);
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong',life: 3000 });

        });      
        
  
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    
}


const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
        </React.Fragment>
    )
}


const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
            {process.env.REACT_APP_MODE === "dev" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={() => confirmDeleteProduct(rowData)} />}
        </React.Fragment>
    );
}

const header = (
    <div className="table-header">
        <h5 className="mx-0 my-1">Manage Albums</h5>
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
    </div>
);
const productDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
      </div>
    </React.Fragment>
);
const updateDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideupdateDialog} />
        <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
      </div>
    </React.Fragment>
);
const deleteProductDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
    </React.Fragment>
);


    const [users, setUsers] = useState([]); 
    const [catcount, setCatCount] = useState("");

    const datafetch = ()=>{
            catdatafetch () 
        .then((res) => {
    
        setCatCount(res.data.data.rows.length)
        setUsers(res.data.data.rows)
        })
        .catch((err) => {
        
        });
    }
    
    useEffect(() => {
          datafetch()
        }, [])

    return (
      <>
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

              <DataTable ref={dt} value={users} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} category"
                  globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                  {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column> */}
                  <Column field="name"  header="Category Name" sortable alignHeader='center' className='text-center' style={{ minWidth: '12rem'}}></Column>
                  {/* <Column field="icon" header="Icon" alignHeader='center' className='text-center'  style={{ minWidth: '16rem' }}></Column> */}
                  {/* <Column field="profile_pic" header="Profile Pic" body={imageBodyTemplate}></Column> */}
        
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
          </div>

          <Dialog visible={productDialog} style={{ width: '450px' }} header="Add Category" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
              {/* {product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />} */}
             {/* {counterr && <small className="p-error mb-2">Cannot add more than 3 category</small>} */}
                
            <form method='post'>
              <div className="field">
                  <label htmlFor="name">Name</label>
                  <InputText id="name" onChange={(e) => setName(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !name })} />
                  {submitted && !name && <small className="p-error">Name is required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="shortname">Short Name</label>
                  <InputText id="shortname" onChange={(e) => setShortName(e.target.value)} required className={classNames({ 'p-invalid': submitted && !shortname })} />
                  {submitted && !shortname && <small className="p-error">Shortname is required.</small>}
              </div>
            </form>
          </Dialog>
          

          <Dialog visible={updateDialog} style={{ width: '450px' }} header="Update Category" modal className="p-fluid" footer={updateDialogFooter} onHide={hideupdateDialog}>
              {/* {product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />} */}

              <div className="field">
                  <label htmlFor="name">Name</label>
                  <InputText id="name" value={updatename} onChange={(e) => setupdateName(e.target.value)} required autoFocus className={classNames({ 'p-invalid': updated && !updatename })} />
                  {updated && !updatename && <small className="p-error">Name is required.</small>}
              </div>
              <div className="field">
                  <label htmlFor="shortname">Short Name</label>
                  <InputText id="shortname" value={updateshortname} onChange={(e) => setshortupdateName(e.target.value)} required className={classNames({ 'p-invalid': updated && !updateshortname })} />
                  {updated && !updateshortname && <small className="p-error">Shortname is required.</small>}
              </div>
              {/* <div className="field">
                  <label htmlFor="description">Icon</label>
                  <InputTextarea id="icon"  value={updateicon} onChange={(e) => setupdateIcon(e.target.value)} required rows={3} cols={20} className={classNames({ 'p-invalid': updated && !updateicon })}  />
                  {updated && !updateicon && <small className="p-error">Icon is required.</small>}

              </div>

              <div className="field">
                  <label className="mb-3">Upload Icon</label>      
                      <FileUpload name="demo" url="./upload" mode="basic" />     
              </div> */}
            
            
          </Dialog>


          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
              </div>
          </Dialog>

          
      </div>
      </div>
      </div>
      </div>
      </>
      );
}

