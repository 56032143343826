import React, { useState, useEffect,useCallback, useRef  } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../DataTableDemo.css';
import Navbar from "../navbar/Navbar";
import { InputText } from 'primereact/inputtext';
import Sidebar from "../sidebar/Sidebar";
import { Calendar } from 'primereact/calendar';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useNavigate  } from 'react-router-dom';
import { deletesubscriberswaiting, getpricingdata, subscriberswaitinglist, subscriberwaitinglistsearch, updatesubscribers } from '../Apicall';

export default function Subcriberswaitinglist() {
    
const navigate = useNavigate()

const [updateDialog,   setupdateDialog] = useState(false);
const [selectedProducts, setSelectedProducts] = useState(null);
const [deleteProductDialog, setDeleteProductDialog] = useState(false);

const toast = useRef(null);
const dt = useRef(null);



const hideupdateDialog = () => {

    setupdateDialog(false);
}


const [updateuserdata, setupdateuserdata] = useState('')

const [name, setname] = useState('')

const editProduct = (data) => {
   
    setupdateDialog(true)
    setupdateuserdata(data)
    setupdateStartDate(data.start_date)
    setupdateEndDate(data.period)
    
    setname(data.User.name)
}

const [updateendvalue, setUpdateendvalue] = useState("")

const updateendHandler = ((e)=>{

    const dateformat = new Date(e)
    const date = dateformat.getDate()
    let month = dateformat.getMonth() +1
    const year = dateformat.getFullYear()
    month = month <=9 ? "0"+month : month
   const updatedate = year+"-"+month+"-"+date
   setUpdateendvalue(updatedate)

   
})

const [updatestartdate, setupdateStartDate] = useState()
const [updateenddate, setupdateEndDate] = useState()
const [updateclick, setUpdateclicked] = useState(false)



useEffect(()=>{
    const d = updatestartdate ? new Date(updatestartdate) : new Date();
    const dateformat = new Date(d.setMonth(d.getMonth() + updateenddate))
    const date = dateformat.getDate()
    let month = dateformat.getMonth() +1
    const year = dateformat.getFullYear()
    month = month <=9 ? "0"+month : month
   const updatedate = year+"-"+month+"-"+date
    setUpdateendvalue(updatedate)

    
},[updatestartdate, updateenddate])


const editsubscriber = (()=>{

    const body ={

        id: updateuserdata.user_id,
        start_date: updatestartdate,
        end_date: updateendvalue,
        period: updateenddate,
        status: 1
        }

        
    !updatestartdate ?
      setUpdateclicked(true)
    :
    updatesubscribers(body).then((res)=>{
        // console.log("aproveres",res)
        setupdateDialog(false)
        waitingsubscriberslist()
        toast.current.show({ severity: 'info', summary: 'Update Subscriber', detail: 'Subscriber Updated Successfully',life: 3000 });

    }).catch((err)=>{
       
        setupdateDialog(false)

        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });

    })

})

const [subscriberdeleteid, setsubscribersdeleteid] = useState('')

const deleteProduct = (data) =>{
    setDeleteProductDialog(true)
    setsubscribersdeleteid(data)
    // setname(data.User.name)
}

const deletesubscriberrequest = ()=>{
    const body = { 
        id: subscriberdeleteid.id
    }
    // console.log('body', body)
    deletesubscriberswaiting(body)
    .then((res)=>{
        // console.log(res)
        
        waitingsubscriberslist()
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'info', summary: 'Delete Subscriber Request', detail: 'Request Deleted Successfully',life: 3000 });
    }).catch((err)=>{
        // console.log(err)
        setDeleteProductDialog(false);
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong', life: 3000 });
        
    })


}

const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={()=>editProduct(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-1" onClick={()=>deleteProduct(rowData)} />
            
        </React.Fragment>
    );
}

const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
    
}

const deleteProductDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>deletesubscriberrequest()} />
    </React.Fragment>
);

const [globalfilter, setGlobalFilter] = useState(null)

const header = (
    <div className="table-header">
        <h5 className="mx-0 my-1">Subscribers Waiting list</h5>
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e)=>setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
    </div>
);

const updateDialogFooter = (
    <React.Fragment>
      <div className='mt-0'>
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideupdateDialog} />
        <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={()=>editsubscriber()} />
      </div>
    </React.Fragment>
);


const [first1, setFirst1] = useState(0);
const [pages, settotalPages] = useState(0);

const onCustomPage1 = (event) => {
    setFirst1(event.first);
    
}

const template1 = {
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    'PrevPageLink': (options) => {
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Previous</span>
                <Ripple />
            </button>
        )
    },
    'NextPageLink': (options) => {
      
        
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Next</span>
                <Ripple />
            </button>
        )
    },
    'PageLinks': (options) => {
        
        settotalPages(options.currentPage)
        
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
  
};


const [subscribers, setsubscribers] = useState()
const [subscriberscount, setsubscriberscount] = useState(0)

  const waitingsubscriberslist = () =>{
    const pageno = pages
    subscriberswaitinglist(pageno)
    .then((res)=>{
        // console.log("waitinglist", res);
        setsubscribers(res.data.data.rows)
        setsubscriberscount(res.data.data.count)
    })
    .catch((err)=>{
        // console.log("subcriberserr", err)
    })
  }


  const searchsubscriberswaitinglist = () =>{
    const body = {
        pageno: pages,
        content: globalfilter
    }
    
    subscriberwaitinglistsearch(body)
    .then((res)=>{
        setsubscribers(res.data.data.rows)
        setsubscriberscount(res.data.data.count)
    })
    .catch((err)=>{
        // console.log(err)
    })
  }

useEffect(()=>{
    globalfilter ? searchsubscriberswaitinglist() : waitingsubscriberslist()
},[pages, globalfilter])

const phoneBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            { rowData.User && rowData.User.countrycode} { rowData.User && rowData.User.phoneno}
        </React.Fragment>
    );
}

const [pricingdata, setpricingdata] = useState('')


const label = []

pricingdata&&pricingdata.map( data => (
  label.push({"label": data.period + ` months - Rs. ${data.price}`, "value": data.period})
));

  

const getpricing = useCallback(async() => {
    
  getpricingdata () 
  .then((res) => {
      
      setpricingdata(res.data.data)
      
  })
  .catch((err) => {
      if(err.response.status === 401){
          localStorage.removeItem('token')
          navigate("/");
      }
      // console.log("prierr", err);
      });

})

useEffect(()=>{
  getpricing()
},[])

const [minDate2, setMindate2] = useState()
useEffect(()=>{
   
    const dateformat = new Date(updatestartdate)
    
      const minDate2 = new Date()
    
      const month2 = dateformat && dateformat.getMonth()
      const year2= dateformat && dateformat.getFullYear()
      const date2 = dateformat && dateformat.getDate()
    
      minDate2.setMonth(month2)
      minDate2.setFullYear(year2)
      minDate2.setDate(date2+1)
      setMindate2(minDate2)
    },[updatestartdate])

    return (
      <>
    <div style={{display:'flex'}}>
    <div>
        <Sidebar />
    </div>
    <div style={{width:'85%'}}>
        <Navbar/>   

      <div className='content'>
      <div className="datatable-crud-demo m-2">
          <Toast ref={toast} className='toast' />

          <div className="card">
           
              <DataTable ref={dt} lazy value={subscribers} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id" paginator
                  
                  paginatorTemplate={template1} first={first1} rows={10} totalRecords={subscriberscount}
                  onPage={onCustomPage1} 
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Subscribers"
                    header={header} responsiveLayout="scroll">
                
            
                <Column field="req_date"  header="Request Date" alignHeader='center' className='text-center' style={{ minWidth: '7rem'}}></Column>
                  <Column field="User.name"  header="Name" alignHeader='center' className='text-center' style={{ minWidth: '12rem'}}></Column>
                  <Column field="User.email"  header="Email" alignHeader='center' className='text-center' style={{ minWidth: '12rem'}}></Column>
                  <Column body={phoneBodyTemplate} header="Phone No" alignHeader='center' className='text-center'  style={{ minWidth: '16rem' }}></Column>

                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
              </DataTable>
    
          </div>

    

          <Dialog visible={updateDialog} style={{ width: '370px' }} header="Approve Subscribers" modal className="p-fluid" footer={updateDialogFooter} onHide={hideupdateDialog}>
            <div className="field" sx={{textAlign: 'center'}}>
                <label >Subscriber Name : <strong>{name}</strong></label>   
                <h3 className="mt-1"></h3>
                <label >Request Date : <strong>{updateuserdata.req_date}</strong></label>   
                
              </div>
              <div className="field">
                <label >Start Date</label>  
                <Calendar value={new Date(updatestartdate)} placeholder={updatestartdate} onChange={(e) => setupdateStartDate(e.value)}  dateFormat="yy-mm-dd" showIcon />
                {!updatestartdate && updateclick && <small className="p-error">Enter Start Date</small>}
              </div>
              <div className="field">
                <label >Period</label>  
              
                <Dropdown value={updateenddate} options={label} optionLabel="label" optionValue="value" onChange={(e) => setupdateEndDate(e.value)} placeholder="Select value..." className='mt-2 w-12' />
                {!updateenddate && updateclick &&<small className="p-error">Enter Subscription Period</small>} 
              </div>

              <div className="field mt-4">
                <label >End Date</label>  
               
                <Calendar value={new Date(updateendvalue)} minDate={minDate2} onChange={(e) => updateendHandler(e.value)} className="p-invalid"  dateFormat="yy-mm-dd" showIcon />
                {!updateendvalue && updateclick &&<small className="p-error">Enter Subscription Period</small>} 
              </div>

          </Dialog>


          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                  {subscriberdeleteid && <span>Are you sure you want to delete <b>{name}</b>?</span>}
              </div>
          </Dialog>

         
      </div>
      </div>
      </div>
      </div>
      </>
      );
}

