import React, { useRef, useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import { getsubscribers, subscriberswaitinglist } from '../Apicall';
import { Link } from 'react-router-dom';
        


export default function Navbar(){

    
    const menu = useRef(null);  
    
    const items = [
        {

            label: 'Account',
            items: [
                {
                    label: 'Reset Password',
                    icon: 'pi pi-refresh',
                    command:()=>{ window.location="/setnewpassword"; }
                   
                },
                {
                    label: 'Log Out',
                    icon: 'pi pi-fw pi-power-off',
                    command:()=>{removeaccessToken() }
                   
                },
            ]
             

        }

    ]

    const removeaccessToken = () =>{
        localStorage.removeItem("token")
        window.location.reload(true)
     }

     const [subscriberscount, setsubscriberscount] = useState()
  
     const waitingsubscriberslist = () =>{
       const pageno = 0
       subscriberswaitinglist(pageno)
       .then((res)=>{
           setsubscriberscount(res.data.data.count)
       })
       .catch((err)=>{
           // console.log("subcriberserr", err)
       })
     }

     useEffect(()=>{
        waitingsubscriberslist()
     },[])

    return(
        
        <div  style={{flex:5, width:"85%", position:'fixed', marginLeft:'195px',zIndex:1}}>
            
                    <div className='topbar layout-topbar layout-topbar-sticky'>
                        <div className='wrapper'>
                            <div className='togge-btn'>
                                {/* <i className="pi pi-align-justify icon" onClick={(event) => sidemenu.current.toggle(event)}  style={{'fontSize': '21px'}}></i> */}
                            </div>
                            <div className='items'>
                              
                                <div className='item'>
                                    <Link to="/subscriberswaitinglist">
                                    <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.3rem', marginRight: '1.3rem', marginTop: '4px' }}>
                                        { subscriberscount > 0 && <Badge value={subscriberscount} size='1px'></Badge>  } 
                                    </i>
                                    </Link>
                                     <Menu model={items} popup ref={menu} id="popup_menu" />
                                    <i className="pi pi-user icon" onClick={(event) => menu.current.toggle(event) } style={{'fontSize': '21px', cursor: 'pointer'}}></i> 
                                </div>  
                            </div>
                        </div>  
                    </div>

                
        </div>
    )
}
